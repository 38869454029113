import { graphql } from "gatsby"

export const BblTestimonialSliderBlockFragment = graphql`
  fragment BblTestimonialSliderBlock on WpBlock {
    ... on WpBblTestimonialSliderBlock {
      name
      attributes {
        className
        testimonialSliderHeading
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblTestimonialSliderBlockPreview = `
  ... on BblTestimonialSliderBlock {
      name
      attributes {
          className
          testimonialSliderHeading
      }
  }
`
