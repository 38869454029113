export default (theme) => ({ // eslint-disable-line
    bannerMessage: {
        position: 'relative',
        '& > .gatsby-image-wrapper': {
            marginLeft: '50%',
            transform: 'translateX(-50%)',
            maxWidth: '100vw',
            width: 'calc(100% + 50px) !important',
            zIndex: '1',
            overflow: 'hidden',
            backgroundColor: '#333',
            backgroundPosition: 'center !important',
            backgroundSize: 'cover !important',
            '& > div': {
                paddingBottom: '550px !important',
            },
            '& .video-wrapper': {
                position: 'absolute',
                top: '50%',
                bottom: 0,
                width: '100%',
                paddingBottom: '0 !important',
                height: '56vw',
                minHeight: 595,
                minWidth: 1070,
                left: '50%',
                transform: 'translate(-50%, -50%)',
                '& iframe': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100% !important',
                    height: '100% !important',
                    minHeight: 595,
                    minWidth: 1070,
                },
            },
            [theme.breakpoints.up('md')]: {
                height: 'auto !important',
                width: '100vw !important',
                '& > div': {
                    paddingBottom: '640px !important',
                }
            },
        },
        '& .content-wrap': {
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            transform: 'translateY(-50%)',
            zIndex: '3',
            '& h1, & h2, & h3, & h4, & h5, & h6' : {
                color: 'white',
                margin: '12px 0',
            },
            '& .button': {
                margin: 12,
            }
        },
    }
})