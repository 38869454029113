import { graphql } from "gatsby";

export const BblContactCalloutBlockFragment = graphql`
  fragment BblContactCalloutBlock on WpBlock {
    ... on WpBblContactCalloutBlock {
        name
        attributes {
            ... on WpBblContactCalloutBlockAttributes {
                className
            }
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblContactCalloutBlockPreview = `
    ... on BblContactCalloutBlock {
        name
        attributes {
            ... on BblContactCalloutBlockAttributes {
                className
            }
        }
    }
`;