import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { BblTestimonialBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import ReactHtmlParser from "react-html-parser"
import styles from "./styles"

export const BblTestimonialBlock = ({
  innerBlocks,
  attributes,
  parentAttributes,
  pageContext,
}) => {
  const data = useStaticQuery(graphql`
    query testimonialOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            testimonial {
              backgroundColour
              quoteHeaderColour
              quoteColour
              nameRoleColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.testimonial
  const useStyles = makeStyles(theme => styles(theme, overrides))

  const classes = useStyles()

  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)

  return (
    <div
      className={`testimonial ${
        attributes.className ? attributes.className : ""
      } ${classes.testimonial}`}
    >
      <div className="wrapper">
        <p className="testimonial-quote">
          {ReactHtmlParser(attributes.testimonialQuote)}
        </p>
        {blocks.map(block => {
          return block
        })}
        <p className="testimonial-name">
          {ReactHtmlParser(attributes.testimonialName)}
        </p>
        <p className="testimonial-role">
          {ReactHtmlParser(attributes.testimonialRole)}
        </p>
      </div>
    </div>
  )
}

export { BblTestimonialBlockPreview }
