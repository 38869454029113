import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"

export const BblFormIntroBlock = props => {
  const data = useStaticQuery(graphql`
    query formIntroOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            formIntro {
              backgroundColour
              headerColour
              paragraphColour
              formBackgroundColour
              formSubmissionMessageTextColour
            }
            forms {
              fieldColour
            }
          }
        }
      }
    }
  `)

  const overrides = data.wp.themeOptions.ThemeOptions.formIntro
  overrides.fieldColour = data.wp.themeOptions.ThemeOptions.forms.fieldColour
  const useStyles = makeStyles(theme => styles(theme, overrides))
  const classes = useStyles()

  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )

  return (
    <div
      className={` ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.formIntro} `}
    >
      <div className="wrapper">
        {blocks[0]}
        {blocks[1]}
        <div className="form-wrap">{blocks[2]}</div>
      </div>
    </div>
  )
}

export const BblFormIntroBlockFragment = graphql`
  fragment BblFormIntroBlock on WpBlock {
    ... on WpBblFormIntroBlock {
      name
      attributes {
        className
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP Prefixes
export const BblFormIntroBlockPreview = `
    ... on BblFormIntroBlock {
        name
        attributes {
            className
        }
    }
`
