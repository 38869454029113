const styles = (theme, overrides) => ({
  testimonial: {
    backgroundColor: "var(--" + overrides.backgroundColour + ")",
    padding: "30px 0",
    [theme.breakpoints.up("md")]: {
      padding: "60px 0",
    },
    "& p": {
      display: "block",
      position: "relative",
      fontSize: 16,
      lineHeight: 1.5,
      marginTop: 0,
      marginBottom: 16,
      fontFamily: theme.bodyFont,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "0 12px",
      flex: "0 0 83.333%",
      color: "var(--" + overrides.quoteColour + ")",
      "&.testimonial-quote": {
        fontFamily: theme.headerFont,
        fontWeight: "bold",
        color: "var(--" + overrides.quoteHeaderColour + ")",
        display: "flex",
        alignItems: "flex-start",
        fontSize: 28,
        lineHeight: 1.333,
        marginBottom: 12,
        [theme.breakpoints.up("lg")]: {
          fontSize: 32,
          lineHeight: 1.5,
        },
        "&:before": {
          content: "'“'",
          transform: "scaleX(4) scaleY(4)",
          transformOrigin: "50% 33%",
          margin: ".33rem 3rem .33rem 1.5rem",
        },
      },
      "&.testimonial-name, &.testimonial-role": {
        color: "var(--" + overrides.nameRoleColour + ")",
        flex: "0 0 41.666%",
      },
      "&.testimonial-name": {
        fontWeight: "bold",
        marginLeft: "8.333%",
        marginRight: 0,
      },
      "&.testimonial-role": {
        fontStyle: "italic",
        flex: "0 0 41.666%",
        textAlign: "right",
        marginLeft: 0,
      },
    },
  },
})

export default styles
