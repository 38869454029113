
export default (theme, overrides) => ({ // eslint-disable-line
    overlapBanner: {
        backgroundColor: 'var(--' + overrides.backgroundColour + ')',
        padding: '120px 0',
        [theme.breakpoints.up('md')]: {
            padding: '100px 0',
        },
        '& .wrapper': {
            alignItems: 'center',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row-reverse',
            },
            '& .con-wrap': {
                marginBottom: -24,
                position: 'relative',
                zIndex: 1,
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 41.333%',
                    minWidth: '41.333%',
                    marginLeft: '-8.333%',
                    marginRight: '16.666%',
                },
                '& .MuiTypography-root': {
                    color: 'var(--' + overrides.headerColour + ')',
                },
                '& .button': {
                    marginTop: 32,
                    marginLeft: 12,
                    marginBottom: 0,
                }
            },
            '& .gatsby-image-wrapper': {
                flex: '0 0 calc(100% - 24px)',
                margin: '0 auto',
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 calc(41.33% - 24px)',
                    margin: '0 12px 0 calc(8.333% + 12px)'
                }
            }
        }
    },
})