import React from 'react';
import PropTypes from 'prop-types';
import { BblVideoBannerMessageBlockPreview } from './queries';
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import styles from './styles';

export const BblVideoBannerMessageBlock = ({
    attributes,
    innerBlocks,
    parentAttributes,
    pageContext
}) => {
    const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext);
    const useStyles = makeStyles(theme => styles(theme));
    const classes = useStyles();

    let poster = {backgroundImage: 'url(' + attributes.mediaUrl + ')'}

    if(attributes.localFile != null) {
        poster = {backgroundImage: 'url(' + attributes.localFile.childImageSharp.fluid.srcWebp + '), url(' + attributes.localFile.childImageSharp.fluid.base64 + ')' }
    }

    return <div
        className={`banner-message ${attributes.className ? attributes.className : ''} ${classes.bannerMessage}`}
    >
        <div className="gatsby-image-wrapper" style={poster}>
            <div style={{paddingBottom: '50%'}} />
            <div className="video-wrapper">
                <iframe title={"video-banner-"+attributes.videoID}
                        src={"https://player.vimeo.com/video/"+attributes.videoID+"?allowfullscreen=1&amp;webkitallowfullscreen=1&autoplay=1&muted=1&background=1"}
                        frameBorder="0"
                        allowFullScreen
                />
            </div>
        </div>
        <div className="content-wrap">
            <div className="wrapper">
                {blocks.map((block) => {
                    return block;
                })}
            </div>
        </div>
    </div>
};

BblVideoBannerMessageBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export { BblVideoBannerMessageBlockPreview }