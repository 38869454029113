export default (theme, overrides) => ({
  // eslint-disable-line
  featuredIconsInner: {
    flex: "0 0 50%",
    paddingBottom: 152,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      flex: "0 0 33.333%",
    },
    "& p": {
      color: "var(--" + overrides.paragraphColour + ")",
      "&:before": {
        display: "block",
        height: 1,
        width: "100%",
        backgroundColor: "var(--" + overrides.paragraphColour + ")",
        content: '""',
        marginBottom: 10,
      },
    },
    "& .gatsby-image-wrapper": {
      width: 128,
      position: "absolute",
      left: 12,
      bottom: 24,
    },
  },
})
