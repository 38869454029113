import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { BblContactCalloutBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"

export const BblContactCalloutBlock = ({
  attributes,
  innerBlocks,
  parentAttributes,
  pageContext,
}) => {
  const data = useStaticQuery(graphql`
    query contactCalloutOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            contactCallout {
              borderColour
              backgroundColour
              headerColour
              paragraphColour
            }
          }
        }
      }
    }
  `)
  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)

  let overrides = data.wp.themeOptions.ThemeOptions.contactCallout

  const useStyles = makeStyles(theme => styles(theme, overrides))
  const classes = useStyles()

  return (
    <div
      className={`contact-callout ${
        attributes.className ? attributes.className : ""
      } ${classes.contactCallout}`}
    >
      <div className="wrapper">
        <div className="content">
          {blocks[3]}
          {blocks[4]}
        </div>
        <div className="buttons">
          {blocks[2]}
          {blocks[1]}
          {blocks[0]}
        </div>
      </div>
    </div>
  )
}

export { BblContactCalloutBlockPreview }
