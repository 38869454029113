import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const BblIconRowBlock = props => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query iconRowOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            iconRow {
              backgroundColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.iconRow

  return (
    <div
      className={` ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.iconRow} `}
      data-override-background={
        overrides.backgroundColour !== "tertiary"
          ? overrides.backgroundColour
          : null
      }
    >
      <div className="wrapper">
        {blocks.map(block => {
          return block
        })}
      </div>
    </div>
  )
}

export const BblIconRowBlockFragment = graphql`
  fragment BblIconRowBlock on WpBlock {
    ... on WpBblIconRowBlock {
      name
      attributes {
        className
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblIconRowBlockPreview = `
    ... on BblIconRowBlock {
        name
        attributes {
            className
        }
    }
`
