import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { BblFeaturedIconsBlockPreview } from "./queries";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';
import {CoreHeadingBlock} from "../CoreHeadingBlock";

export const BblFeaturedIconsBlock = ({
    attributes,
    innerBlocks,
    parentAttributes,
    pageContext
}) => {

    const data = useStaticQuery(graphql`
        query featuredIconsOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        featuredIcons {
                            backgroundColour
                            headerColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.featuredIcons;
    const useStyles = makeStyles(theme => styles(theme, overrides));

    const classes = useStyles();

    const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext);

    return <div className={`featured-icons ${attributes.className ? attributes.className : ''} ${classes.featuredIcons}`}>
        <div className="wrapper">
            <CoreHeadingBlock 
                attributes={{
                    align: null,
                    anchor: "",
                    className: null,
                    content: ReactHtmlParser(attributes.featuredIconsTitle),
                    backgroundColor: "initial",
                    level: 2,
                    textColor: null,
                    __typename: "wp_CoreHeadingBlockAttributes",
                }}
                innerBlocks={[]} 
            />
            <div className="icons">
                {blocks.map((block) => {
                    return block;
                })}
            </div>
        </div>
    </div>
};

export { BblFeaturedIconsBlockPreview }