import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { BblImageLinkSliderInnerBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import Link from "../../Link"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import styles from "./styles"

export const BblImageLinkSliderInnerBlock = ({ attributes }) => {
  const data = useStaticQuery(graphql`
    query imageLinkSliderInnerOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            imageLinkSlider {
              linkTextColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.imageLinkSlider
  const useStyles = makeStyles(theme => styles(theme, overrides))

  const classes = useStyles()

  let image = null

  if (attributes.mediaUrlSharp) {
    if (attributes.mediaUrlSharp.childImageSharp) {
      image = attributes.mediaUrlSharp.publicURL
    }
  } else {
    image = attributes.mediaUrl
  }

  return (
    <Link
      to={attributes.linkURL}
      style={{ backgroundImage: "url(" + image + ")" }}
      className={`image-link-slider-inner ${
        attributes.className ? attributes.className : ""
      } ${classes.imageLinkSliderInner}`}
    >
      <span className="sub-text">{attributes.subText}</span>
      <span className="text">{attributes.text}</span>
      <ArrowForwardIcon />
    </Link>
  )
}

BblImageLinkSliderInnerBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { BblImageLinkSliderInnerBlockPreview }
