import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby";
import { BblBannerNavSliderBlockPreview } from "./queries";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import Slider from "react-slick/lib";
import styles from "./styles";

import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"

export const BblBannerNavSliderBlock = ({
    attributes,
    innerBlocks,
    parentAttributes,
    pageContext
}) => {

    const data = useStaticQuery(graphql`
        query bannerNavOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        bannerNavSlider {
                            controlColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.bannerNavSlider;

    const useStyles = makeStyles(theme => styles(theme, overrides));

    const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext);
    const classes = useStyles();

    const settings = {
        dots: true,
        infinite: true,
        centerMode: false,
        nextArrow: <ArrowForwardIcon />,
        prevArrow: <ArrowBackIcon />,
        slidesToScroll: 1,
        variableWidth: false,
        fade: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: false
    }

    return <div className={`${attributes.className ? attributes.className : ''} ${classes.bannerNavSlider}`} >
        <Slider {...settings}>
            {blocks.map((block) => {
                return block;
            })}
        </Slider>
    </div>
};

BblBannerNavSliderBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export { BblBannerNavSliderBlockPreview }