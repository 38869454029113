import { graphql } from "gatsby";

export const BblOverlapBannerBlockFragment = graphql`
    fragment BblOverlapBannerBlock on WpBlock {
        ... on WpBblOverlapBannerBlock {
            name
            attributes {
                className
            }
        }
    }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblOverlapBannerBlockPreview = `
    ... on BblOverlapBannerBlock {
        name
        attributes {
            className
        }
    }
`;