export default (theme, overrides) => ({ // eslint-disable-line
    contactCallout: {
        border: '15px solid var(--' + overrides.borderColour + ')',
        padding: '30px 15px',
        backgroundColor: 'var(--' + overrides.backgroundColor + ')',
        [theme.breakpoints.up('md')]: {
            borderWidth: 30,
            padding: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '90px 30px',
        },
        '& .wrapper': {
            [theme.breakpoints.up('md')]: {
                alignItems: 'center',
            },
            '& .content': {
                flex: '0 0 100%',
                minWidth: '100%',
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 50%',
                    minWidth: '50%',
                },
                '& .MuiTypography-root': {
                    marginBottom: 24,
                },
                '& p': {
                    marginBottom: 32,
                    [theme.breakpoints.up('md')]: {
                        marginBottom: 0,
                    }
                }
            },
            '& .buttons': {
                flex: '0 0 100%',
                minWidth: '100%',
                '& .button': {
                    width: 'calc(100% - 24px)',
                },
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 50%',
                    minWidth: '50%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    '& .button': {
                        flex: '0 0 calc(33.333% - 24px)',
                        margin: '0 12px',
                        paddingTop: 12,
                        paddingBottom: 12,
                        textAlign: 'center !important',
                        '& span.material-icons': {
                            display: 'block',
                            margin: '0 auto !important',
                            width: 24,
                            height: 24,
                            fontSize: 24,
                        }
                    }
                }
            }
        }
    },
})