const styles = (theme, overrides) => ({
  logoSlider: {
    backgroundImage: "var(--" + overrides.backgroundColour + ")",
    overflow: "hidden",
    position: "relative",
    "&:after": {
      position: "absolute",
      display: "block",
      content: '""',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      boxShadow:
        "inset 0 0 15px 15px var(--" + overrides.backgroundColour + ")",
      [theme.breakpoints.up("md")]: {
        boxShadow:
          "inset 0 0 50px 25px var(--" + overrides.backgroundColour + ")",
      },
    },
    "& .slick-slider": {
      width: 220,
      margin: "0 auto",
      transform: "translateX(-120px)",
      [theme.breakpoints.up("md")]: {
        width: 370,
        transform: "translateX(-270px)",
      },
      "& .slick-list": {
        overflow: "visible !important",
      },
      "& .slick-track": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .slick-slide": {
        padding: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
          padding: 75,
        },
      },
    },
  },
})

export default styles
