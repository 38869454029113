import { graphql } from "gatsby";

export const BblFeaturedIconsInnerBlockFragment = graphql`
fragment BblFeaturedIconsInnerBlock on WpBlock {
  ... on WpBblFeaturedIconsInnerBlock {
      name
      attributes {
          className
      }
    }
}`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblFeaturedIconsInnerBlockPreview = `
  ... on BblFeaturedIconsInnerBlock {
      name
      attributes {
          className
      }
  }
`;