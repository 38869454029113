import { graphql } from "gatsby";

export const BblVideoBannerMessageBlockFragment = graphql`
    fragment BblVideoBannerMessageBlock on WpBlock {
        ... on WpBblVideoBannerMessageBlock {
            name
            attributes {
                __typename
                ... on WpBblVideoBannerMessageBlockAttributes {
                    className
                    videoID
                    mediaUrl
                    localFile {
                        id
                        publicURL
                        childImageSharp {
                            fluid(quality: 50, maxWidth: 1660) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblVideoBannerMessageBlockPreview = `
    ... on BblVideoBannerMessageBlock {
        name
        attributes {
            __typename
            ... on BblVideoBannerMessageBlockAttributes {
                className
                videoID
                mediaUrl
            }
        }
    }
`;