import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import PreviewImage from "../../PreviewImage"

import { CoreHeadingBlock } from "../CoreHeadingBlock"
import { CoreParagraphBlock } from "../CoreParagraphBlock"

import RoomIcon from "@material-ui/icons/Room"
import PhoneIcon from "@material-ui/icons/Phone"
import EmailIcon from "@material-ui/icons/Email"
import W3wIcon from "../../w3w-icon"

import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const AcfGlobalContactInformationImageBlock = props => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query globalContactInformationImageBlock {
      wp {
        businessInformation {
          LocationData {
            timesJson
            address1
            address2
            email
            fieldGroupName
            name
            postcode
            region
            telephone
            telephone2
            town
          }
        }
        themeOptions {
          ThemeOptions {
            contactInformation {
              backgroundColour
              columnBackgroundColour
              iconColour
              linkColour
              textColour
              borderColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.contactInformation

  const { className } = props.attributes
  const sourceUrl = props.block.GlobalContactInformationBlock.image

  const branch = {
    BranchInformation: data.wp.businessInformation.LocationData,
    OpeningHours: JSON.parse(
      data.wp.businessInformation.LocationData.timesJson
    ),
  }

  return (
    <div
      className={`${classes.globalContactInformationImage} ${
        className ? className : ""
      } border-${overrides.borderColour}`}
      data-override-background={
        overrides.backgroundColour !== "background"
          ? overrides.backgroundColour
          : null
      }
    >
      <div className="wrapper">
        {branch && (
          <>
            {sourceUrl && className.indexOf("osm") === -1 && (
              <PreviewImage
                image={
                  props.block.GlobalContactInformationBlock.image.localFile
                }
                fallbackUrl={sourceUrl}
                alt={""}
                fixedPadding="100%"
                className="map-col"
              />
            )}

            {sourceUrl && className.indexOf("osm") !== -1 && (
              <div className="map-col" style={{ position: "relative" }}>
                <PreviewImage
                  image={
                    props.block.GlobalContactInformationBlock.image.localFile
                  }
                  fallbackUrl={sourceUrl}
                  alt={""}
                />
                <div
                  className="copyright"
                  style={{
                    color: "#333",
                    right: "0",
                    bottom: "0",
                    padding: "2px 5px",
                    zIndex: 1,
                    position: "absolute",
                    fontSize: 12,
                    fontFamily: "sans-serif",
                    backgroundColor: "rgba(255,255,255,0.5)",
                  }}
                >
                  ©{" "}
                  <a
                    href="https://www.openstreetmap.org/copyright"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0078a8" }}
                  >
                    OpenStreetMap
                  </a>{" "}
                  contributors
                </div>
              </div>
            )}

            <div
              className="address-col"
              data-override-background={
                overrides.columnBackgroundColour !== "tertiary"
                  ? overrides.columnBackgroundColour
                  : null
              }
            >
              <CoreHeadingBlock
                attributes={{
                  textAlign: "left",
                  anchor: "",
                  className: "",
                  textColor:
                    overrides.textColour !== "on-tertiary"
                      ? overrides.textColour
                      : "on-tertiary",
                  backgroundColor: "",
                  content: "Find Us",
                  level: 3,
                  __typename: "WpCoreHeadingBlockAttributes",
                }}
                innerBlocks={[]}
              />
              <CoreParagraphBlock
                attributes={{
                  align: "left",
                  className: "",
                  textColor:
                    overrides.textColour !== "on-tertiary"
                      ? overrides.textColour
                      : "on-tertiary",
                  backgroundColor: "",
                  fontSize: "",
                  content: `${
                    branch.BranchInformation.displayLocation
                      ? `<strong>${branch.BranchInformation.name}</strong><br/>`
                      : ""
                  }
                            ${
                              branch.BranchInformation.address1
                                ? `${branch.BranchInformation.address1}<br/>`
                                : ""
                            }
                            ${
                              branch.BranchInformation.address2
                                ? `${branch.BranchInformation.address2}<br/>`
                                : ""
                            }
                            ${
                              branch.BranchInformation.town
                                ? `${branch.BranchInformation.town}<br/>`
                                : ""
                            }
                            ${
                              branch.BranchInformation.postcode
                                ? `${branch.BranchInformation.postcode}<br/>`
                                : ""
                            }`,
                  preParagraph: (
                    <RoomIcon
                      data-override-color={
                        overrides.iconColour !== "accent"
                          ? overrides.iconColour
                          : null
                      }
                    />
                  ),
                  __typename: "WpCoreParagraphBlockAttributes",
                }}
                innerBlocks={[]}
              />
              {branch.BranchInformation.telephone && (
                <p>
                  <PhoneIcon
                    data-override-color={
                      overrides.iconColour !== "accent"
                        ? overrides.iconColour
                        : null
                    }
                  />
                  <a
                    href={"tel:" + branch.BranchInformation.telephone}
                    data-override-color={
                      overrides.linkColour !== "accent"
                        ? overrides.linkColour
                        : null
                    }
                  >
                    {branch.BranchInformation.telephone}
                  </a>
                </p>
              )}
              {branch.BranchInformation.telephone2 && (
                <p>
                  <PhoneIcon
                    data-override-color={
                      overrides.iconColour !== "accent"
                        ? overrides.iconColour
                        : null
                    }
                  />
                  <a
                    href={"tel:" + branch.BranchInformation.telephone2}
                    data-override-color={
                      overrides.linkColour !== "accent"
                        ? overrides.linkColour
                        : null
                    }
                  >
                    {branch.BranchInformation.telephone2}
                  </a>
                </p>
              )}
              {branch.BranchInformation.email && (
                <p>
                  <EmailIcon
                    data-override-color={
                      overrides.iconColour !== "accent"
                        ? overrides.iconColour
                        : null
                    }
                  />
                  <a
                    href={"mailto:" + branch.BranchInformation.email}
                    data-override-color={
                      overrides.linkColour !== "accent"
                        ? overrides.linkColour
                        : null
                    }
                  >
                    {branch.BranchInformation.email}
                  </a>
                </p>
              )}

              {branch.BranchInformation.what3words && (
                <p>
                  <W3wIcon
                    data-override-color={
                      overrides.iconColour !== "accent"
                        ? overrides.iconColour
                        : null
                    }
                  />
                  <a
                    href={`https://what3words.com/${branch.BranchInformation.what3words}`}
                    data-override-color={
                      overrides.linkColour !== "accent"
                        ? overrides.linkColour
                        : null
                    }
                  >
                    {branch.BranchInformation.what3words}
                  </a>
                </p>
              )}
            </div>

            <div
              className="hours-col"
              data-override-background={
                overrides.columnBackgroundColour !== "tertiary"
                  ? overrides.columnBackgroundColour
                  : null
              }
            >
              <CoreHeadingBlock
                attributes={{
                  textAlign: "left",
                  anchor: "",
                  className: "",
                  textColor:
                    overrides.textColour !== "on-tertiary"
                      ? overrides.textColour
                      : "on-tertiary",
                  backgroundColor: "",
                  content: "Opening Hours",
                  level: 3,
                  __typename: "WpCoreHeadingBlockAttributes",
                }}
                innerBlocks={[]}
              />
              <CoreParagraphBlock
                attributes={{
                  align: "left",
                  className: "",
                  textColor:
                    overrides.textColour !== "on-tertiary"
                      ? overrides.textColour
                      : "on-tertiary",
                  backgroundColor: "",
                  fontSize: "",
                  content:
                    branch.OpeningHours.monday.open === null
                      ? "<strong>Monday</strong> Closed"
                      : "<strong>Monday</strong><span>" +
                        branch.OpeningHours.monday.open +
                        "</span> <i>-</i> <span>" +
                        branch.OpeningHours.monday.close +
                        "</span>",
                  __typename: "WpCoreParagraphBlockAttributes",
                }}
                innerBlocks={[]}
              />
              <CoreParagraphBlock
                attributes={{
                  content:
                    branch.OpeningHours.tuesday.open === null
                      ? "<strong>Tuesday</strong> Closed"
                      : "<strong>Tuesday</strong><span>" +
                        branch.OpeningHours.tuesday.open +
                        "</span> <i>-</i> <span>" +
                        branch.OpeningHours.tuesday.close +
                        "</span>",
                  textColor:
                    overrides.textColour !== "on-tertiary"
                      ? overrides.textColour
                      : "on-tertiary",

                  __typename: "WpCoreParagraphBlockAttributes",
                }}
                innerBlocks={[]}
              />
              <CoreParagraphBlock
                attributes={{
                  align: "left",
                  className: "",
                  textColor:
                    overrides.textColour !== "on-tertiary"
                      ? overrides.textColour
                      : "on-tertiary",
                  backgroundColor: "",
                  fontSize: "",
                  content:
                    branch.OpeningHours.wednesday.open === null
                      ? "<strong>Wednesday</strong> Closed"
                      : "<strong>Wednesday</strong><span>" +
                        branch.OpeningHours.wednesday.open +
                        "</span> <i>-</i> <span>" +
                        branch.OpeningHours.wednesday.close +
                        "</span>",
                  __typename: "WpCoreParagraphBlockAttributes",
                }}
                innerBlocks={[]}
              />
              <CoreParagraphBlock
                attributes={{
                  align: "left",
                  className: "",
                  textColor:
                    overrides.textColour !== "on-tertiary"
                      ? overrides.textColour
                      : "on-tertiary",
                  backgroundColor: "",
                  fontSize: "",
                  content:
                    branch.OpeningHours.thursday.open === null
                      ? "<strong>Thursday</strong> Closed"
                      : "<strong>Thursday</strong><span>" +
                        branch.OpeningHours.thursday.open +
                        "</span> <i>-</i> <span>" +
                        branch.OpeningHours.thursday.close +
                        "</span>",
                  __typename: "WpCoreParagraphBlockAttributes",
                }}
                innerBlocks={[]}
              />
              <CoreParagraphBlock
                attributes={{
                  align: "left",
                  className: "",
                  textColor:
                    overrides.textColour !== "on-tertiary"
                      ? overrides.textColour
                      : "on-tertiary",
                  backgroundColor: "",
                  fontSize: "",
                  content:
                    branch.OpeningHours.friday.open === null
                      ? "<strong>Friday</strong> Closed"
                      : "<strong>Friday</strong><span>" +
                        branch.OpeningHours.friday.open +
                        "</span> <i>-</i> <span>" +
                        branch.OpeningHours.friday.close +
                        "</span>",
                  __typename: "WpCoreParagraphBlockAttributes",
                }}
                innerBlocks={[]}
              />
              <CoreParagraphBlock
                attributes={{
                  align: "left",
                  className: "",
                  textColor:
                    overrides.textColour !== "on-tertiary"
                      ? overrides.textColour
                      : "on-tertiary",
                  backgroundColor: "",
                  fontSize: "",
                  content:
                    branch.OpeningHours.saturday.open === null
                      ? "<strong>Saturday</strong> Closed"
                      : "<strong>Saturday</strong><span>" +
                        branch.OpeningHours.saturday.open +
                        "</span> <i>-</i> <span>" +
                        branch.OpeningHours.saturday.close +
                        "</span>",
                  __typename: "WpCoreParagraphBlockAttributes",
                }}
                innerBlocks={[]}
              />
              <CoreParagraphBlock
                attributes={{
                  align: "left",
                  className: "",
                  textColor:
                    overrides.textColour !== "on-tertiary"
                      ? overrides.textColour
                      : "on-tertiary",
                  backgroundColor: "",
                  fontSize: "",
                  content:
                    branch.OpeningHours.sunday === null
                      ? "<strong>Sunday</strong> Closed"
                      : "<strong>Sunday</strong><span>" +
                        branch.OpeningHours.sunday.open +
                        "</span> <i>-</i> <span>" +
                        branch.OpeningHours.sunday.close +
                        "</span>",
                  __typename: "WpCoreParagraphBlockAttributes",
                }}
                innerBlocks={[]}
              />
              {branch.OpeningHours.special !== null &&
                <CoreParagraphBlock
                  attributes={{
                    align: "left",
                    className: "",
                    textColor:
                      overrides.textColour !== "on-tertiary"
                        ? overrides.textColour
                        : "on-tertiary",
                    backgroundColor: "",
                    fontSize: "",
                    content:"<strong>" + branch.OpeningHours.special.name + "</strong><span>" +
                      branch.OpeningHours.special.open +
                      "</span> <i>-</i> <span>" +
                      branch.OpeningHours.special.close +
                      "</span>",
                    __typename: "WpCoreParagraphBlockAttributes",
                  }}
                  innerBlocks={[]}
                />
              }
            </div>
          </>
        )}
      </div>
    </div>
  )
}

AcfGlobalContactInformationImageBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfGlobalContactInformationImageBlockFragment = graphql`
  fragment AcfGlobalContactInformationImageBlock on WpBlock {
    ... on WpAcfGlobalContactInformationImageBlock {
      attributes {
        className
      }
      GlobalContactInformationBlock {
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1140
                aspectRatio: 2
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: COVER, cropFocus: CENTER }
                quality: 90
              )
            }
          }
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfGlobalContactInformationImageBlockPreview = `
    ... on AcfGlobalContactInformationImageBlock {
    name
        attributes {
        __typename
            ... on AcfGlobalContactInformationImageBlockAttributes {
            className
            }                      
        }
    }
}
`
