import React from "react"

import { CoreBlock } from "../components/blocks/CoreBlock"
import { CoreParagraphBlock } from "../components/blocks/CoreParagraphBlock/"
import { CoreGalleryBlock } from "../components/blocks/CoreGalleryBlock/"
import { CoreHeadingBlock } from "../components/blocks/CoreHeadingBlock/"
import { CoreImageBlock } from "../components/blocks/CoreImageBlock/"
import { CoreListBlock } from "../components/blocks/CoreListBlock/"
import { CoreSpacerBlock } from "../components/blocks/CoreSpacerBlock/"

import { AcfAdvertSliderBlock } from "../components/blocks/AcfAdvertSliderBlock"
import { AcfBackgroundContainerBlock } from "../components/blocks/AcfBackgroundContainerBlock"
import { AcfBackgroundImageContainerBlock } from "../components/blocks/AcfBackgroundImageContainerBlock"
import { AcfBrewGalleryBlock } from "../components/blocks/AcfBrewGalleryBlock"
import { AcfButtonBlock } from "../components/blocks/AcfButtonBlock"
import { AcfFacebookPagePluginBlock } from "../components/blocks/AcfFacebookPagePluginBlock"
import { AcfGoogleReviewsBlock } from "../components/blocks/AcfGoogleReviewsBlock"
import { AcfGlobalContactInformationImageBlock } from "../components/blocks/AcfGlobalContactInformationImageBlock"
import { AcfImageSliderBlock } from "../components/blocks/AcfImageSliderBlock"
import { AcfJobsListBlock } from "../components/blocks/AcfJobsListBlock"
import { AcfLatestPostsGridBlock } from "../components/blocks/AcfLatestPostsGridBlock"
import { AcfLatestPostsSliderBlock } from "../components/blocks/AcfLatestPostsSliderBlock"
import { AcfLogoRowBlock } from "../components/blocks/AcfLogoRowBlock"
import { AcfLogoSliderBlock } from "../components/blocks/AcfLogoSliderBlock"
import { AcfPageListingBlock } from "../components/blocks/AcfPageListingBlock"
import { AcfPdfReaderBlock } from "../components/blocks/AcfPdfReaderBlock"
import { AcfPeopleGridBlock } from "../components/blocks/AcfPeopleGridBlock"
import { AcfTimedContainerBlock } from "../components/blocks/AcfTimedContainerBlock"

import { BblBannerMessageBlock } from "../components/blocks/BblBannerMessageBlock"
import { BblBannerMessageSliderBlock } from "../components/blocks/BblBannerMessageSliderBlock"
import { BblBannerNavSliderBlock } from "../components/blocks/BblBannerNavSliderBlock"
import { BblBannerNavSliderInnerBlock } from "../components/blocks/BblBannerNavSliderInnerBlock"
import { BblBannerStackedBlock } from "../components/blocks/BblBannerStackedBlock"
import { BblBranchIntroBlock } from "../components/blocks/BblBranchIntroBlock"
import { BblBranchListingBlock } from "../components/blocks/BblBranchListingBlock"
import { BblBrandDetailBlock } from "../components/blocks/BblBrandDetailBlock"
import { BblBrandLogosBlock } from "../components/blocks/BblBrandLogosBlock"
import { BblCalloutCtaBlock } from "../components/blocks/BblCalloutCtaBlock"
import { BblCardBlock } from "../components/blocks/BblCardBlock"
import { BblCardRowBlock } from "../components/blocks/BblCardRowBlock"
import { BblContactCalloutBlock } from "../components/blocks/BblContactCalloutBlock"
import { BblContactInformationImageBlock } from "../components/blocks/BblContactInformationImageBlock"
import { BblContactInformationMapBlock } from "../components/blocks/BblContactInformationMapBlock"
import { BblFeaturedIconsBlock } from "../components/blocks/BblFeaturedIconsBlock"
import { BblFeaturedIconsInnerBlock } from "../components/blocks/BblFeaturedIconsInnerBlock"
import { BblFiftyStackedContentBlock } from "../components/blocks/BblFiftyStackedContentBlock"
import { BblFormIntroBlock } from "../components/blocks/BblFormIntroBlock"
import { BblGoogleMapBlock } from "../components/blocks/BblGoogleMapBlock"
import { BblIconRowBlock } from "../components/blocks/BblIconRowBlock"
import { BblIconRowInnerBlock } from "../components/blocks/BblIconRowInnerBlock"
import { BblIntroGalleryBlock } from "../components/blocks/BblIntroGalleryBlock"
import { BblImageContentRowsBlock } from "../components/blocks/BblImageContentRowsBlock"
import { BblImageContentRowsInnerBlock } from "../components/blocks/BblImageContentRowsInnerBlock"
import { BblImageLinkSliderBlock } from "../components/blocks/BblImageLinkSliderBlock"
import { BblImageLinkSliderInnerBlock } from "../components/blocks/BblImageLinkSliderInnerBlock"
import { BblJobDetailBlock } from "../components/blocks/BblJobDetailBlock"
import { BblManagerInfoBlock } from "../components/blocks/BblManagerInfoBlock"
import { BblMediaItemBlock } from "../components/blocks/BblMediaItemBlock"
import { BblPageTitleBlock } from "../components/blocks/BblPageTitleBlock"
import { BblPostBannerBlock } from "../components/blocks/BblPostBannerBlock"
import { BblPostListingCardGridBlock } from "../components/blocks/BblPostListingCardGridBlock"
import { BblPostListingFullWidthBlock } from "../components/blocks/BblPostListingFullWidthBlock"
import { BblPostWrapBlock } from "../components/blocks/BblPostWrapBlock"
import { BblPartsGridBlock } from "../components/blocks/BblPartsGridBlock"
import { BblOverlapBannerBlock } from "../components/blocks/BblOverlapBannerBlock"
import { BblSideCardBlock } from "../components/blocks/BblSideCardBlock"
import { BblSideCardGridBlock } from "../components/blocks/BblSideCardGridBlock"
import { BblSplitPageTitleBlock } from "../components/blocks/BblSplitPageTitleBlock"
import { BblSplitPostTitleBlock } from "../components/blocks/BblSplitPostTitleBlock"
import { BblStatBreakdownBlock } from "../components/blocks/BblStatBreakdownBlock"
import { BblStatBreakdownInnerBlock } from "../components/blocks/BblStatBreakdownInnerBlock"
import { BblStickyPostFullWidthBlock } from "../components/blocks/BblStickyPostFullWidthBlock"
import { BblStickyPostSplitBlock } from "../components/blocks/BblStickyPostSplitBlock"
import { BblTestimonialBlock } from "../components/blocks/BblTestimonialBlock"
import { BblTestimonialSliderBlock } from "../components/blocks/BblTestimonialSliderBlock"
import { BblTextIntroBlock } from "../components/blocks/BblTextIntroBlock"
import { BblTwoColumnTextIntroBlock } from "../components/blocks/BblTwoColumnTextIntroBlock"
import { BblTwoColumnWrapperBlock } from "../components/blocks/BblTwoColumnWrapperBlock"
import { BblTwoColumnWrapperInnerBlock } from "../components/blocks/BblTwoColumnWrapperInnerBlock"
import { BblVideoBannerMessageBlock } from "../components/blocks/BblVideoBannerMessageBlock"

import { GravityformsFormBlock } from "../components/blocks/GravityformsFormBlock"

/**
 * Returns the component for the block name
 * @param {String} name
 */
function mapBlocks(name) {
  let block = null
  switch (name) {
    case "CoreBlock":
      block = CoreBlock
      break
    case "CoreParagraphBlock":
      block = CoreParagraphBlock
      break
    case "CoreGalleryBlock":
      block = CoreGalleryBlock
      break
    case "CoreHeadingBlock":
      block = CoreHeadingBlock
      break
    case "CoreImageBlock":
      block = CoreImageBlock
      break
    case "CoreListBlock":
      block = CoreListBlock
      break
    case "CoreSpacerBlock":
      block = CoreSpacerBlock
      break

    case "AcfAdvertSliderBlock":
      block = AcfAdvertSliderBlock
      break
    case "AcfBackgroundContainerBlock":
      block = AcfBackgroundContainerBlock
      break
    case "AcfBackgroundImageContainerBlock":
      block = AcfBackgroundImageContainerBlock
      break
    case "AcfBrewGalleryBlock":
      block = AcfBrewGalleryBlock
      break
    case "AcfButtonBlock":
      block = AcfButtonBlock
      break
    case "AcfFacebookPagePluginBlock":
      block = AcfFacebookPagePluginBlock
      break
    case "AcfGoogleReviewsBlock":
      block = AcfGoogleReviewsBlock
      break
    case "AcfGlobalContactInformationImageBlock":
      block = AcfGlobalContactInformationImageBlock
      break
    case "AcfImageSliderBlock":
      block = AcfImageSliderBlock
      break
    case "AcfJobsListBlock":
      block = AcfJobsListBlock
      break
    case "AcfLatestPostsGridBlock":
      block = AcfLatestPostsGridBlock
      break
    case "AcfLatestPostsSliderBlock":
      block = AcfLatestPostsSliderBlock
      break
    case "AcfLogoRowBlock":
      block = AcfLogoRowBlock
      break
    case "AcfLogoSliderBlock":
      block = AcfLogoSliderBlock
      break
    case "AcfPageListingBlock":
      block = AcfPageListingBlock
      break
    case "AcfPdfReaderBlock":
      block = AcfPdfReaderBlock
      break
    case "AcfPeopleGridBlock":
      block = AcfPeopleGridBlock
      break
    case "AcfTimedContainerBlock":
      block = AcfTimedContainerBlock
      break

    case "BblBannerMessageBlock":
      block = BblBannerMessageBlock
      break
    case "BblBannerMessageSliderBlock":
      block = BblBannerMessageSliderBlock
      break
    case "BblBannerNavSliderBlock":
      block = BblBannerNavSliderBlock
      break
    case "BblBannerNavSliderInnerBlock":
      block = BblBannerNavSliderInnerBlock
      break
    case "BblBannerStackedBlock":
      block = BblBannerStackedBlock
      break
    case "BblBranchIntroBlock":
      block = BblBranchIntroBlock
      break
    case "BblBranchListingBlock":
      block = BblBranchListingBlock
      break
    case "BblBrandDetailBlock":
      block = BblBrandDetailBlock
      break
    case "BblBrandLogosBlock":
      block = BblBrandLogosBlock
      break
    case "BblCalloutCtaBlock":
      block = BblCalloutCtaBlock
      break
    case "BblCardBlock":
      block = BblCardBlock
      break
    case "BblCardRowBlock":
      block = BblCardRowBlock
      break
    case "BblContactCalloutBlock":
      block = BblContactCalloutBlock
      break
    case "BblContactInformationImageBlock":
      block = BblContactInformationImageBlock
      break
    case "BblContactInformationMapBlock":
      block = BblContactInformationMapBlock
      break
    case "BblFeaturedIconsBlock":
      block = BblFeaturedIconsBlock
      break
    case "BblFeaturedIconsInnerBlock":
      block = BblFeaturedIconsInnerBlock
      break
    case "BblFiftyStackedContentBlock":
      block = BblFiftyStackedContentBlock
      break
    case "BblFormIntroBlock":
      block = BblFormIntroBlock
      break
    case "BblGoogleMapBlock":
      block = BblGoogleMapBlock
      break
    case "BblIconRowBlock":
      block = BblIconRowBlock
      break
    case "BblIconRowInnerBlock":
      block = BblIconRowInnerBlock
      break
    case "BblIntroGalleryBlock":
      block = BblIntroGalleryBlock
      break
    case "BblImageContentRowsBlock":
      block = BblImageContentRowsBlock
      break
    case "BblImageContentRowsInnerBlock":
      block = BblImageContentRowsInnerBlock
      break
    case "BblImageLinkSliderBlock":
      block = BblImageLinkSliderBlock
      break
    case "BblImageLinkSliderInnerBlock":
      block = BblImageLinkSliderInnerBlock
      break
    case "BblJobDetailBlock":
      block = BblJobDetailBlock
      break
    case "BblManagerInfoBlock":
      block = BblManagerInfoBlock
      break
    case "BblMediaItemBlock":
      block = BblMediaItemBlock
      break
    case "BblPageTitleBlock":
      block = BblPageTitleBlock
      break
    case "BblPartsGridBlock":
      block = BblPartsGridBlock
      break
    case "BblPostBannerBlock":
      block = BblPostBannerBlock
      break
    case "BblPostListingCardGridBlock":
      block = BblPostListingCardGridBlock
      break
    case "BblPostListingFullWidthBlock":
      block = BblPostListingFullWidthBlock
      break
    case "BblPostWrapBlock":
      block = BblPostWrapBlock
      break
    case "BblOverlapBannerBlock":
      block = BblOverlapBannerBlock
      break
    case "BblSideCardBlock":
      block = BblSideCardBlock
      break
    case "BblSideCardGridBlock":
      block = BblSideCardGridBlock
      break
    case "BblSplitPageTitleBlock":
      block = BblSplitPageTitleBlock
      break
    case "BblSplitPostTitleBlock":
      block = BblSplitPostTitleBlock
      break
    case "BblStatBreakdownBlock":
      block = BblStatBreakdownBlock
      break
    case "BblStatBreakdownInnerBlock":
      block = BblStatBreakdownInnerBlock
      break
    case "BblStickyPostFullWidthBlock":
      block = BblStickyPostFullWidthBlock
      break
    case "BblStickyPostSplitBlock":
      block = BblStickyPostSplitBlock
      break
    case "BblTestimonialBlock":
      block = BblTestimonialBlock
      break
    case "BblTestimonialSliderBlock":
      block = BblTestimonialSliderBlock
      break
    case "BblTextIntroBlock":
      block = BblTextIntroBlock
      break
    case "BblTwoColumnTextIntroBlock":
      block = BblTwoColumnTextIntroBlock
      break
    case "BblTwoColumnWrapperBlock":
      block = BblTwoColumnWrapperBlock
      break
    case "BblTwoColumnWrapperInnerBlock":
      block = BblTwoColumnWrapperInnerBlock
      break
    case "BblVideoBannerMessageBlock":
      block = BblVideoBannerMessageBlock
      break

    case "GravityformsFormBlock":
      block = GravityformsFormBlock
      break

    default:
      block = null
      break
  }
  return block
}

function UCFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Converts the block name in to a component name
 * eg. core/banner = CoreBannerBlock
 * @param {String} name
 */
function convertName(name) {
  const split = name.split("/")
  const parts = []
  parts.push(UCFirst(split[0]))
  const split2 = split[1].split("-")
  split2.map(part => {
    return parts.push(UCFirst(part))
  })
  return parts.join("") + (name === "core/block" ? "" : "Block")
}

export const buildBlocks = (
  blocks,
  parentAttributes = {},
  pageContext = {}
) => {
  if (!blocks) return []
  // Render the blocks
  let processed = []
  blocks.map((block, index) => {
    if (!block.name) return false
    let elem = null

    // Convert the block name to a component name
    const component = convertName(block.name)
    let OutputBlock = null

    OutputBlock = mapBlocks(component)

    elem = OutputBlock ? (
      <OutputBlock
        key={index}
        attributes={block.attributes}
        parentAttributes={parentAttributes}
        originalContent={block.originalContent}
        innerBlocks={
          component === "CoreBlock"
            ? block.reusableBlock.blocks
            : block.innerBlocks
        }
        pageContext={pageContext}
        block={block}
      />
    ) : null

    return processed.push(elem)
  })
  return processed
}

export const mergeBlocks = (coreBlocks, customBlocks) => {
  let blocks = coreBlocks
  customBlocks.map((block, index) => {
    if (block.name) {
      blocks[index] = block
    }
    return null
  })
  return blocks
}
