import { graphql } from "gatsby"

export const BblImageLinkSliderInnerBlockFragment = graphql`
  fragment BblImageLinkSliderInnerBlock on WpBlock {
    ... on WpBblImageLinkSliderInnerBlock {
      name
      attributes {
        __typename
        ... on WpBblImageLinkSliderInnerBlockAttributes {
          className
          subText
          text
          linkURL
          mediaUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1660
                aspectRatio: 1.75
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: COVER, cropFocus: CENTER }
                quality: 90
              )
            }
          }
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblImageLinkSliderInnerBlockPreview = `
  ... on BblImageLinkSliderInnerBlock {
      name
      attributes {
          __typename
          ... on BblImageLinkSliderInnerBlockAttributes {
              className
              subText
              text
              linkURL
              mediaUrl
          }
      }
  }
`
