import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { AcfLogoRowBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"

import styles from "./styles"
import PreviewImage from "../../PreviewImage"

export const AcfLogoRowBlock = props => {
  const data = useStaticQuery(graphql`
    query logoRowOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            logoRow {
              backgroundColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.logoRow

  const useStyles = makeStyles(theme => styles(theme, overrides))
  const classes = useStyles()

  let images = props.block.blockLogoRow.images

  if (images === null) {
    return null
  }

  return (
    <div className={"logo-row " + classes.logoRow}>
      <div className="wrapper">
        {images.map((image, index) => (
          <PreviewImage
            key={"i-" + index}
            alt={image.id}
            className="logo-item"
            fallbackUrl
            image={image.localFile}
          />
        ))}
      </div>
    </div>
  )
}

AcfLogoRowBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { AcfLogoRowBlockPreview }
