export default theme => ({
  // eslint-disable-line
  brandLogos: {
    padding: "32px 0",
    [theme.breakpoints.up("md")]: {
      padding: "50px 0 100px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingBottom: 200,
    },
    "& .MuiTypography-root": {
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        marginBottom: 50,
      },
    },
    "& .wrapper": {
      flexDirection: "row-reverse",
    },
    "& nav": {
      borderBottom: "2px solid " + theme.palette.accent.main,
      flex: "0 0 calc(100% - 24px)",
      maxWidth: "calc(100% - 24px)",
      margin: "0 12px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-around",
      alignItems: "center",
      paddingBottom: 12,
      "& button": {
        flex: "0 0 7%",
        maxWidth: "7%",
        border: "none",
        backgroundColor: "transparent",
        fontSize: 16,
        fontFamily: theme.headerFont,
        fontWeight: "bold",
        color: theme.palette.accent.main,
        opacity: 0.5,
        padding: 6,
        transition: "opacity .3s ease-in-out",
        [theme.breakpoints.up(700)]: {
          flex: "0 0 3.5%",
          maxWidth: "3.5%",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: 18,
        },
        "&:hover, &:focus": {
          opacity: 1,
        },
        '&[data-active="true"]': {
          color: theme.palette.accent.main,
          opacity: 1,
        },
      },
    },
    "& .list-filters": {
      flex: "0 0 100%",
      maxWidth: "100%",
      padding: 12,
      [theme.breakpoints.up(500)]: {
        flex: "0 0 calc(100% - 125px)",
        maxWidth: "calc(100% - 125px)",
        padding: "40px 12px",
      },
      "& button": {
        lineHeight: 1.5,
        fontFamily: theme.subHeaderFont,
        fontWeight: "bold",
        display: "inline-block",
        marginRight: 12,
        position: "relative",
        borderRadius: theme.border.radius,
        border: "none",
        transition: "opacity .3s ease-in-out",
        boxShadow: theme.shadow,
        opacity: 1,
        fontSize: 12,
        padding: "8px 12px",
        marginBottom: 12,
        [theme.breakpoints.up("md")]: {
          fontSize: 16,
          padding: "8px 24px",
          marginBottom: 0,
        },
        "&:hover, &:focus": {
          opacity: 0.8,
        },
        '&[data-active="true"]': {
          opacity: 0.4,
        },
      },
    },
    "& .list-style": {
      textAlign: "right",
      padding: 12,
      flex: "0 0 100%",
      maxWidth: "100%",
      [theme.breakpoints.up(500)]: {
        flex: "0 0 125px",
        maxWidth: "125px",
        marginLeft: "auto",
        padding: "40px 12px",
      },
      "& button": {
        fontSize: 0,
        border: "none",
        backgroundColor: "transparent",
        padding: 0,
        marginLeft: 12,
        color: theme.palette.accent.main,
        opacity: 0.5,
        transition: "opacity .3s ease-in-out",
        "& svg": {
          width: 35,
          height: 35,
        },
        "&:hover, &:focus": {
          opacity: 1,
        },
        '&[data-active="true"]': {
          color: theme.palette.accent.main,
          opacity: 1,
        },
      },
    },
    "& .brand-list": {
      flex: "0 0 100%",
      maxWidth: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      "& > div": {
        padding: 12,
        flex: "0 0 100%",
        maxWidth: "100%",
        [theme.breakpoints.up("sm")]: {
          flex: "0 0 50%",
          maxWidth: "50%",
        },
        "& a": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          overflow: "hidden",
          borderRadius: theme.border.radius,
          boxShadow: theme.shadow,
          textTransform: "uppercase",
          letterSpacing: "0.032em",
          fontSize: 16,
          fontFamily: theme.bodyFont,
          width: "100%",
          transition: "opacity .3s ease-in-out",
          "& .gatsby-image-wrapper": {
            flex: "0 0 150px",
            maxWidth: "150px",
            marginRight: 12,
          },
          "&:hover, &:focus": {
            color: theme.palette.primary.contrastText,
            opacity: 0.8,
          },
        },
      },
      "&.grid": {
        "& > div": {
          flex: "0 0 100%",
          maxWidth: "100%",
          [theme.breakpoints.up(274)]: {
            flex: "0 0 50%",
            maxWidth: "50%",
          },
          [theme.breakpoints.up(548)]: {
            flex: "0 0 33.333%",
            maxWidth: "33.333%",
          },
          [theme.breakpoints.up(822)]: {
            flex: "0 0 25%",
            maxWidth: "25%",
          },
          [theme.breakpoints.up(1096)]: {
            flex: "0 0 20%",
            maxWidth: "20%",
          },
          [theme.breakpoints.up(1330)]: {
            flex: "0 0 16.666%",
            maxWidth: "16.666%",
          },
          "& a": {
            display: "block",
            padding: 0,
            height: "100%",
            "& .gatsby-image-wrapper": {
              width: "calc(100% + 2px)",
              maxWidth: "calc(100% + 2px)",
              margin: "0 -2px",
            },
            "& span": {
              padding: 24,
              display: "block",
            },
          },
        },
      },
    },
    "&.filter-primary": {
      "& nav": {
        borderBottom: "2px solid " + theme.palette.primary.main,
        "& button": {
          color: theme.palette.primary.main,
          '&[data-active="true"]': {
            color: theme.palette.primary.main,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.palette.primary.main,
          '&[data-active="true"]': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    "&.filter-on-primary": {
      "& nav": {
        borderBottom: "2px solid " + theme.palette.primary.contrastText,
        "& button": {
          color: theme.palette.primary.contrastText,
          '&[data-active="true"]': {
            color: theme.palette.primary.contrastText,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.palette.primary.contrastText,
          '&[data-active="true"]': {
            color: theme.palette.primary.contrastText,
          },
        },
      },
    },
    "&.filter-primary-highlight": {
      "& nav": {
        borderBottom: "2px solid " + theme.palette.primary.highlight,
        "& button": {
          color: theme.palette.primary.highlight,
          '&[data-active="true"]': {
            color: theme.palette.primary.highlight,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.palette.primary.highlight,
          '&[data-active="true"]': {
            color: theme.palette.primary.highlight,
          },
        },
      },
    },

    "&.filter-secondary": {
      "& nav": {
        borderBottom: "2px solid " + theme.palette.secondary.main,
        "& button": {
          color: theme.palette.secondary.main,
          '&[data-active="true"]': {
            color: theme.palette.secondary.main,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.palette.secondary.main,
          '&[data-active="true"]': {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    "&.filter-on-secondary": {
      "& nav": {
        borderBottom: "2px solid " + theme.palette.secondary.contrastText,
        "& button": {
          color: theme.palette.secondary.contrastText,
          '&[data-active="true"]': {
            color: theme.palette.secondary.contrastText,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.palette.secondary.contrastText,
          '&[data-active="true"]': {
            color: theme.palette.secondary.contrastText,
          },
        },
      },
    },
    "&.filter-secondary-highlight": {
      "& nav": {
        borderBottom: "2px solid " + theme.palette.secondary.highlight,
        "& button": {
          color: theme.palette.secondary.highlight,
          '&[data-active="true"]': {
            color: theme.palette.secondary.highlight,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.palette.secondary.highlight,
          '&[data-active="true"]': {
            color: theme.palette.secondary.highlight,
          },
        },
      },
    },

    "&.filter-tertiary": {
      "& nav": {
        borderBottom: "2px solid " + theme.palette.tertiary.main,
        "& button": {
          color: theme.palette.tertiary.main,
          '&[data-active="true"]': {
            color: theme.palette.tertiary.main,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.palette.tertiary.main,
          '&[data-active="true"]': {
            color: theme.palette.tertiary.main,
          },
        },
      },
    },
    "&.filter-on-tertiary": {
      "& nav": {
        borderBottom: "2px solid " + theme.palette.tertiary.contrastText,
        "& button": {
          color: theme.palette.tertiary.contrastText,
          '&[data-active="true"]': {
            color: theme.palette.tertiary.contrastText,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.palette.tertiary.contrastText,
          '&[data-active="true"]': {
            color: theme.palette.tertiary.contrastText,
          },
        },
      },
    },
    "&.filter-tertiary-highlight": {
      "& nav": {
        borderBottom: "2px solid " + theme.palette.tertiary.highlight,
        "& button": {
          color: theme.palette.tertiary.highlight,
          '&[data-active="true"]': {
            color: theme.palette.tertiary.highlight,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.palette.tertiary.highlight,
          '&[data-active="true"]': {
            color: theme.palette.tertiary.highlight,
          },
        },
      },
    },

    "&.filter-accent": {
      "& nav": {
        borderBottom: "2px solid " + theme.palette.accent.main,
        "& button": {
          color: theme.palette.accent.main,
          '&[data-active="true"]': {
            color: theme.palette.accent.main,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.palette.accent.main,
          '&[data-active="true"]': {
            color: theme.palette.accent.main,
          },
        },
      },
    },
    "&.filter-on-accent": {
      "& nav": {
        borderBottom: "2px solid " + theme.palette.accent.contrastText,
        "& button": {
          color: theme.palette.accent.contrastText,
          '&[data-active="true"]': {
            color: theme.palette.accent.contrastText,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.palette.accent.contrastText,
          '&[data-active="true"]': {
            color: theme.palette.accent.contrastText,
          },
        },
      },
    },
    "&.filter-accent-highlight": {
      "& nav": {
        borderBottom: "2px solid " + theme.palette.accent.highlight,
        "& button": {
          color: theme.palette.accent.highlight,
          '&[data-active="true"]': {
            color: theme.palette.accent.highlight,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.palette.accent.highlight,
          '&[data-active="true"]': {
            color: theme.palette.accent.highlight,
          },
        },
      },
    },

    "&.filter-background": {
      "& nav": {
        borderBottom: "2px solid " + theme.palette.background.default,
        "& button": {
          color: theme.palette.background.default,
          '&[data-active="true"]': {
            color: theme.palette.background.default,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.palette.background.default,
          '&[data-active="true"]': {
            color: theme.palette.background.default,
          },
        },
      },
    },
    "&.filter-black": {
      "& nav": {
        borderBottom: "2px solid " + theme.common.black,
        "& button": {
          color: theme.common.black,
          '&[data-active="true"]': {
            color: theme.common.black,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.common.black,
          '&[data-active="true"]': {
            color: theme.common.black,
          },
        },
      },
    },
    "&.filter-white": {
      "& nav": {
        borderBottom: "2px solid " + theme.common.white,
        "& button": {
          color: theme.common.white,
          '&[data-active="true"]': {
            color: theme.common.white,
          },
        },
      },
      "& .list-style": {
        "& button": {
          color: theme.common.white,
          '&[data-active="true"]': {
            color: theme.common.white,
          },
        },
      },
    },
  },
})
