export default (theme, overrides) => ({ // eslint-disable-line
    featuredIcons: {
        position: 'relative',
        padding: '85px 0',
        backgroundColor: 'var(--' + overrides.backgroundColour + ')',
        '& .wrapper': {
            '& h2': {
                color: 'var(--' + overrides.headerColour + ')',
                position: 'relative',
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 41.666%',
                    maxWidth: '41.666%',
                }
            },
            '& .icons': {
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                flex: '0 0 100%',
                minWidth: '100%',
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 58.333%',
                    minWidth: '48.333%',
                }
            }
        }
    }
})