import { graphql } from "gatsby"

import {
  CoreParagraphBlockFragment, // eslint-disable-line
  CoreParagraphBlockPreview,
} from "../components/blocks/CoreParagraphBlock/"
import {
  CoreHeadingBlockFragment, // eslint-disable-line
  CoreHeadingBlockPreview,
} from "../components/blocks/CoreHeadingBlock"
import {
  CoreGalleryBlockFragment, // eslint-disable-line
  CoreGalleryBlockPreview,
} from "../components/blocks/CoreGalleryBlock"
import {
  CoreImageBlockFragment, // eslint-disable-line
  CoreImageBlockPreview,
} from "../components/blocks/CoreImageBlock"
import {
  CoreListBlockFragment, // eslint-disable-line
  CoreListBlockPreview,
} from "../components/blocks/CoreListBlock"
import {
  CoreSpacerBlockFragment, // eslint-disable-line
  CoreSpacerBlockPreview,
} from "../components/blocks/CoreSpacerBlock"

import {
  AcfAdvertSliderBlockFragment, // eslint-disable-line
  AcfAdvertSliderBlockPreview,
} from "../components/blocks/AcfAdvertSliderBlock"
import {
  AcfBackgroundContainerBlockFragment, // eslint-disable-line
  AcfBackgroundContainerBlockPreview,
} from "../components/blocks/AcfBackgroundContainerBlock"
import {
  AcfBackgroundImageContainerBlockFragment, // eslint-disable-line
  AcfBackgroundImageContainerBlockPreview,
} from "../components/blocks/AcfBackgroundImageContainerBlock"
import {
  AcfBrewGalleryBlockFragment, // eslint-disable-line
  AcfBrewGalleryBlockPreview,
} from "../components/blocks/AcfBrewGalleryBlock"
import {
  AcfButtonBlockFragment, // eslint-disable-line
  AcfButtonBlockPreview,
} from "../components/blocks/AcfButtonBlock"
import {
  AcfFacebookPagePluginBlockFragment, // eslint-disable-line
  AcfFacebookPagePluginBlockPreview,
} from "../components/blocks/AcfFacebookPagePluginBlock"
import {
  AcfGoogleReviewsBlockFragment, // eslint-disable-line
  AcfGoogleReviewsBlockPreview,
} from "../components/blocks/AcfGoogleReviewsBlock"
import {
  AcfGlobalContactInformationImageBlock, // eslint-disable-line
  AcfGlobalContactInformationImageBlockPreview,
} from "../components/blocks/AcfGlobalContactInformationImageBlock"
import {
  AcfImageSliderBlockFragment, // eslint-disable-line
  AcfImageSliderBlockPreview,
} from "../components/blocks/AcfImageSliderBlock"
import {
  AcfJobsListBlockFragment, // eslint-disable-line
  AcfJobsListBlockPreview,
} from "../components/blocks/AcfJobsListBlock"
import {
  AcfLatestPostsGridBlockFragment, // eslint-disable-line
  AcfLatestPostsGridBlockPreview,
} from "../components/blocks/AcfLatestPostsGridBlock"
import {
  AcfLatestPostsSliderBlockFragment, // eslint-disable-line
  AcfLatestPostsSliderBlockPreview,
} from "../components/blocks/AcfLatestPostsSliderBlock"
import {
  AcfLogoRowBlockFragment, // eslint-disable-line
  AcfLogoRowBlockPreview,
} from "../components/blocks/AcfLogoRowBlock"
import {
  AcfLogoSliderBlockFragment, // eslint-disable-line
  AcfLogoSliderBlockPreview,
} from "../components/blocks/AcfLogoSliderBlock"
import {
  AcfPageListingBlockFragment, // eslint-disable-line
  AcfPageListingBlockPreview,
} from "../components/blocks/AcfPageListingBlock"
import {
  AcfPdfReaderBlockFragment, // eslint-disable-line
  AcfPdfReaderBlockPreview,
} from "../components/blocks/AcfPdfReaderBlock"
import {
  AcfPeopleGridBlockFragment, // eslint-disable-line
  AcfPeopleGridBlockPreview,
} from "../components/blocks/AcfPeopleGridBlock"
import {
  AcfTimedContainerBlockFragment, // eslint-disable-line
  AcfTimedContainerBlockPreview,
} from "../components/blocks/AcfTimedContainerBlock"

import {
  BblBannerMessageBlockFragment, // eslint-disable-line
  BblBannerMessageBlockPreview,
} from "../components/blocks/BblBannerMessageBlock"
import {
  BblBannerMessageSliderBlockFragment, // eslint-disable-line
  BblBannerMessageSliderBlockPreview,
} from "../components/blocks/BblBannerMessageSliderBlock"
import {
  BblBannerNavSliderBlockFragment, // eslint-disable-line
  BblBannerNavSliderBlockPreview,
} from "../components/blocks/BblBannerNavSliderBlock"
import {
  BblBannerNavSliderInnerBlockFragment, // eslint-disable-line
  BblBannerNavSliderInnerBlockPreview,
} from "../components/blocks/BblBannerNavSliderInnerBlock"
import {
  BblBannerStackedBlockFragment, // eslint-disable-line
  BblBannerStackedBlockPreview,
} from "../components/blocks/BblBannerStackedBlock"
import {
  BblBranchIntroBlockFragment, // eslint-disable-line
  BblBranchIntroBlockPreview,
} from "../components/blocks/BblBranchIntroBlock"
import {
  BblBranchListingBlockFragment, // eslint-disable-line
  BblBranchListingBlockPreview,
} from "../components/blocks/BblBranchListingBlock"
import {
  BblBrandDetailBlockFragment, // eslint-disable-line
  BblBrandDetailBlockPreview,
} from "../components/blocks/BblBrandDetailBlock"
import {
  BblBrandLogosBlockFragment, // eslint-disable-line
  BblBrandLogosBlockPreview,
} from "../components/blocks/BblBrandLogosBlock"
import {
  BblCalloutCtaBlockFragment, // eslint-disable-line
  BblCalloutCtaBlockPreview,
} from "../components/blocks/BblCalloutCtaBlock"
import {
  BblCardBlockFragment, // eslint-disable-line
  BblCardBlockPreview,
} from "../components/blocks/BblCardBlock"
import {
  BblCardRowBlockFragment, // eslint-disable-line
  BblCardRowBlockPreview,
} from "../components/blocks/BblCardRowBlock"
import {
  BblContactCalloutBlockFragment, // eslint-disable-line
  BblContactCalloutBlockPreview,
} from "../components/blocks/BblContactCalloutBlock"
import {
  BblContactInformationImageBlockFragment, // eslint-disable-line
  BblContactInformationImageBlockPreview,
} from "../components/blocks/BblContactInformationImageBlock"
import {
  BblContactInformationMapBlockFragment, // eslint-disable-line
  BblContactInformationMapBlockPreview,
} from "../components/blocks/BblContactInformationMapBlock"
import {
  BblFeaturedIconsBlockFragment, // eslint-disable-line
  BblFeaturedIconsBlockPreview,
} from "../components/blocks/BblFeaturedIconsBlock"
import {
  BblFeaturedIconsInnerBlockFragment, // eslint-disable-line
  BblFeaturedIconsInnerBlockPreview,
} from "../components/blocks/BblFeaturedIconsInnerBlock"
import {
  BblFiftyStackedContentBlockFragment, // eslint-disable-line
  BblFiftyStackedContentBlockPreview,
} from "../components/blocks/BblFiftyStackedContentBlock"
import {
  BblFormIntroBlockFragment, // eslint-disable-line
  BblFormIntroBlockPreview,
} from "../components/blocks/BblFormIntroBlock"
import {
  BblGoogleMapBlockFragment, // eslint-disable-line
  BblGoogleMapBlockPreview,
} from "../components/blocks/BblGoogleMapBlock"
import {
  BblIconRowBlockFragment, // eslint-disable-line
  BblIconRowBlockPreview,
} from "../components/blocks/BblIconRowBlock"
import {
  BblIconRowInnerBlockFragment, // eslint-disable-line
  BblIconRowInnerBlockPreview,
} from "../components/blocks/BblIconRowInnerBlock"
import {
  BblIntroGalleryBlockFragment, // eslint-disable-line
  BblIntroGalleryBlockPreview,
} from "../components/blocks/BblIntroGalleryBlock"
import {
  BblImageContentRowsBlockFragment, // eslint-disable-line
  BblImageContentRowsBlockPreview,
} from "../components/blocks/BblImageContentRowsBlock"
import {
  BblImageContentRowsInnerBlockFragment, // eslint-disable-line
  BblImageContentRowsInnerBlockPreview,
} from "../components/blocks/BblImageContentRowsInnerBlock"
import {
  BblImageLinkSliderBlockFragment, // eslint-disable-line
  BblImageLinkSliderBlockPreview,
} from "../components/blocks/BblImageLinkSliderBlock"
import {
  BblImageLinkSliderInnerBlockFragment, // eslint-disable-line
  BblImageLinkSliderInnerBlockPreview,
} from "../components/blocks/BblImageLinkSliderInnerBlock"
import {
  BblJobDetailBlockFragment, // eslint-disable-line
  BblJobDetailBlockPreview,
} from "../components/blocks/BblJobDetailBlock"
import {
  BblManagerInfoBlockFragment, // eslint-disable-line
  BblManagerInfoBlockPreview,
} from "../components/blocks/BblManagerInfoBlock"
import {
  BblMediaItemBlockFragment, // eslint-disable-line
  BblMediaItemBlockPreview,
} from "../components/blocks/BblMediaItemBlock"
import {
  BblPageTitleBlockFragment, // eslint-disable-line
  BblPageTitleBlockPreview,
} from "../components/blocks/BblPageTitleBlock"
import {
  BblPostBannerBlockFragment, // eslint-disable-line
  BblPostBannerBlockPreview,
} from "../components/blocks/BblPostBannerBlock"
import {
  BblPostListingFullWidthBlockFragment, // eslint-disable-line
  BblPostListingFullWidthBlockPreview,
} from "../components/blocks/BblPostListingFullWidthBlock"
import {
  BblPostListingCardGridBlockFragment, // eslint-disable-line
  BblPostListingCardGridBlockPreview,
} from "../components/blocks/BblPostListingCardGridBlock"
import {
  BblPostWrapBlockFragment, // eslint-disable-line
  BblPostWrapBlockPreview,
} from "../components/blocks/BblPostWrapBlock"
import {
  BblPartsGridBlockFragment, // eslint-disable-line
  BblPartsGridBlockPreview,
} from "../components/blocks/BblPartsGridBlock"
import {
  BblOverlapBannerBlockFragment, // eslint-disable-line
  BblOverlapBannerBlockPreview,
} from "../components/blocks/BblOverlapBannerBlock"
import {
  BblSideCardBlockFragment, // eslint-disable-line
  BblSideCardBlockPreview,
} from "../components/blocks/BblSideCardBlock"
import {
  BblSideCardGridBlockFragment, // eslint-disable-line
  BblSideCardGridBlockPreview,
} from "../components/blocks/BblSideCardGridBlock"
import {
  BblSplitPageTitleBlockFragment, // eslint-disable-line
  BblSplitPageTitleBlockPreview,
} from "../components/blocks/BblSplitPageTitleBlock"
import {
  BblSplitPostTitleBlockFragment, // eslint-disable-line
  BblSplitPostTitleBlockPreview,
} from "../components/blocks/BblSplitPostTitleBlock"
import {
  BblStatBreakdownBlockFragment, // eslint-disable-line
  BblStatBreakdownBlockPreview,
} from "../components/blocks/BblStatBreakdownBlock"
import {
  BblStatBreakdownInnerBlockFragment, // eslint-disable-line
  BblStatBreakdownInnerBlockPreview,
} from "../components/blocks/BblStatBreakdownInnerBlock"
import {
  BblStickyPostFullWidthBlockFragment, // eslint-disable-line
  BblStickyPostFullWidthBlockPreview,
} from "../components/blocks/BblStickyPostFullWidthBlock"
import {
  BblStickyPostSplitBlockFragment, // eslint-disable-line
  BblStickyPostSplitBlockPreview,
} from "../components/blocks/BblStickyPostSplitBlock"
import {
  BblTestimonialBlockFragment, // eslint-disable-line
  BblTestimonialBlockPreview,
} from "../components/blocks/BblTestimonialBlock"
import {
  BblTestimonialSliderBlockFragment, // eslint-disable-line
  BblTestimonialSliderBlockPreview,
} from "../components/blocks/BblTestimonialSliderBlock"
import {
  BblTextIntroBlockFragment, // eslint-disable-line
  BblTextIntroBlockPreview,
} from "../components/blocks/BblTextIntroBlock"
import {
  BblTwoColumnTextIntroBlockFragment, // eslint-disable-line
  BblTwoColumnTextIntroBlockPreview,
} from "../components/blocks/BblTwoColumnTextIntroBlock"
import {
  BblTwoColumnWrapperBlockFragment, // eslint-disable-line
  BblTwoColumnWrapperBlockPreview,
} from "../components/blocks/BblTwoColumnWrapperBlock"
import {
  BblTwoColumnWrapperInnerBlockFragment, // eslint-disable-line
  BblTwoColumnWrapperInnerBlockPreview,
} from "../components/blocks/BblTwoColumnWrapperInnerBlock"
import {
  BblVideoBannerMessageBlockFragment, // eslint-disable-line
  BblVideoBannerMessageBlockPreview,
} from "../components/blocks/BblVideoBannerMessageBlock"

import {
  GravityformsFormBlockFragment, // eslint-disable-line
  GravityformsFormBlockPreview,
} from "../components/blocks/GravityformsFormBlock"

export const CoreFields = graphql`
  fragment CoreFields on WpBlock {
    name
    ...CoreParagraphBlock
    ...CoreHeadingBlock
    ...CoreGalleryBlock
    ...CoreImageBlock
    ...CoreListBlock
    ...CoreSpacerBlock
    ...AcfAdvertSliderBlock
    ...AcfBackgroundContainerBlock
    ...AcfBackgroundImageContainerBlock
    ...AcfBrewGalleryBlock
    ...AcfButtonBlock
    ...AcfFacebookPagePluginBlock
    ...AcfGoogleReviewsBlock
    ...AcfGlobalContactInformationImageBlock
    ...AcfLogoRowBlock
    ...AcfImageSliderBlock
    ...AcfJobsListBlock
    ...AcfLatestPostsGridBlock
    ...AcfLatestPostsSliderBlock
    ...AcfLogoSliderBlock
    ...AcfPageListingBlock
    ...AcfPdfReaderBlock
    ...AcfPeopleGridBlock
    ...AcfTimedContainerBlock
    ...BblBannerMessageBlock
    ...BblBannerMessageSliderBlock
    ...BblBannerNavSliderBlock
    ...BblBannerNavSliderInnerBlock
    ...BblBannerStackedBlock
    ...BblBranchIntroBlock
    ...BblBranchListingBlock
    ...BblBrandDetailBlock
    ...BblBrandLogosBlock
    ...BblCalloutCtaBlock
    ...BblCardBlock
    ...BblCardRowBlock
    ...BblContactCalloutBlock
    ...BblContactInformationImageBlock
    ...BblContactInformationMapBlock
    ...BblFeaturedIconsBlock
    ...BblFeaturedIconsInnerBlock
    ...BblFiftyStackedContentBlock
    ...BblFormIntroBlock
    ...BblGoogleMapBlock
    ...BblIconRowBlock
    ...BblIconRowInnerBlock
    ...BblIntroGalleryBlock
    ...BblImageContentRowsBlock
    ...BblImageContentRowsInnerBlock
    ...BblImageLinkSliderBlock
    ...BblImageLinkSliderInnerBlock
    ...BblJobDetailBlock
    ...BblManagerInfoBlock
    ...BblMediaItemBlock
    ...BblPageTitleBlock
    ...BblPostBannerBlock
    ...BblPostListingFullWidthBlock
    ...BblPostListingCardGridBlock
    ...BblPostWrapBlock
    ...BblPartsGridBlock
    ...BblOverlapBannerBlock
    ...BblSideCardBlock
    ...BblSideCardGridBlock
    ...BblSplitPageTitleBlock
    ...BblSplitPostTitleBlock
    ...BblStatBreakdownBlock
    ...BblStatBreakdownInnerBlock
    ...BblStickyPostFullWidthBlock
    ...BblStickyPostSplitBlock
    ...BblTestimonialBlock
    ...BblTestimonialSliderBlock
    ...BblTextIntroBlock
    ...BblTwoColumnTextIntroBlock
    ...BblTwoColumnWrapperBlock
    ...BblTwoColumnWrapperInnerBlock
    ...BblVideoBannerMessageBlock
    ...GravityformsFormBlock
  }
`

export const PreviewBlocks = `
  ${CoreParagraphBlockPreview}
  ${CoreHeadingBlockPreview}
  ${CoreGalleryBlockPreview}
  ${CoreImageBlockPreview}
  ${CoreListBlockPreview}
  ${CoreSpacerBlockPreview}

  ${AcfAdvertSliderBlockPreview}
  ${AcfBackgroundContainerBlockPreview}
  ${AcfBackgroundImageContainerBlockPreview}
  ${AcfBrewGalleryBlockPreview}
  ${AcfButtonBlockPreview}
  ${AcfFacebookPagePluginBlockPreview}
  ${AcfGoogleReviewsBlockPreview}
  ${AcfGlobalContactInformationImageBlockPreview}
  ${AcfImageSliderBlockPreview}
  ${AcfJobsListBlockPreview}
  ${AcfLatestPostsGridBlockPreview}
  ${AcfLatestPostsSliderBlockPreview}
  ${AcfLogoRowBlockPreview}
  ${AcfLogoSliderBlockPreview}
  ${AcfPageListingBlockPreview}
  ${AcfPdfReaderBlockPreview}
  ${AcfPeopleGridBlockPreview}
  ${AcfTimedContainerBlockPreview}

  ${BblBannerMessageBlockPreview}
  ${BblBannerMessageSliderBlockPreview}
  ${BblBannerNavSliderBlockPreview}
  ${BblBannerNavSliderInnerBlockPreview}
  ${BblBannerStackedBlockPreview}
  ${BblBranchIntroBlockPreview}
  ${BblBranchListingBlockPreview}
  ${BblBrandDetailBlockPreview}
  ${BblBrandLogosBlockPreview}
  ${BblCalloutCtaBlockPreview}
  ${BblCardBlockPreview}
  ${BblCardRowBlockPreview}
  ${BblContactCalloutBlockPreview}
  ${BblContactInformationImageBlockPreview}
  ${BblContactInformationMapBlockPreview}
  ${BblFeaturedIconsBlockPreview}
  ${BblFeaturedIconsInnerBlockPreview}
  ${BblFiftyStackedContentBlockPreview}
  ${BblFormIntroBlockPreview}
  ${BblGoogleMapBlockPreview}
  ${BblIconRowBlockPreview}
  ${BblIconRowInnerBlockPreview}
  ${BblIntroGalleryBlockPreview}
  ${BblImageContentRowsBlockPreview}
  ${BblImageContentRowsInnerBlockPreview}
  ${BblImageLinkSliderBlockPreview}
  ${BblImageLinkSliderInnerBlockPreview}
  ${BblJobDetailBlockPreview}
  ${BblManagerInfoBlockPreview}
  ${BblMediaItemBlockPreview}
  ${BblPageTitleBlockPreview}
  ${BblPostBannerBlockPreview}
  ${BblPostListingCardGridBlockPreview}
  ${BblPostListingFullWidthBlockPreview}
  ${BblPostWrapBlockPreview}
  ${BblPartsGridBlockPreview}
  ${BblOverlapBannerBlockPreview}
  ${BblSideCardBlockPreview}
  ${BblSideCardGridBlockPreview}
  ${BblSplitPageTitleBlockPreview}
  ${BblSplitPostTitleBlockPreview}
  ${BblStatBreakdownBlockPreview}
  ${BblStatBreakdownInnerBlockPreview}
  ${BblStickyPostFullWidthBlockPreview}
  ${BblStickyPostSplitBlockPreview}
  ${BblTestimonialBlockPreview}
  ${BblTestimonialSliderBlockPreview}
  ${BblTextIntroBlockPreview}
  ${BblTwoColumnTextIntroBlockPreview}
  ${BblTwoColumnWrapperBlockPreview}
  ${BblTwoColumnWrapperInnerBlockPreview}
  ${BblVideoBannerMessageBlockPreview}

  ${GravityformsFormBlockPreview}
  
  `
