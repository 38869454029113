import { graphql } from "gatsby";

export const BblBannerNavSliderInnerBlockFragment = graphql`
fragment BblBannerNavSliderInnerBlock on WpBlock {
    ... on WpBblBannerNavSliderInnerBlock {
        name
        attributes {
            __typename
            ... on WpBblBannerNavSliderInnerBlockAttributes {
                className
                mediaUrl
                mediaUrlMobile
                localFile {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                          width: 1660,
                          aspectRatio: 1.75,
                          placeholder: BLURRED,
                          formats: [AUTO, WEBP, AVIF],
                          transformOptions: {fit: COVER, cropFocus: CENTER}
                        )
                    }
                }
                localFileMobile {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                          width: 768,
                          aspectRatio: 0.85,
                          placeholder: BLURRED,
                          formats: [AUTO, WEBP, AVIF],
                          transformOptions: {fit: COVER, cropFocus: CENTER}
                        )
                    }
                }
            }
        }
    }
}`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblBannerNavSliderInnerBlockPreview = `
... on BblBannerNavSliderInnerBlock {
    name
    attributes {
        __typename
        ... on BblBannerNavSliderInnerBlockAttributes {
            className
            hasBackground
            mediaUrl
            mediaUrlMobile
        }
    }
}
`;