export default (theme, overrides) => ({ // eslint-disable-line
  bannerNavSlider: {
    "& .slick-slider": {
      "& .slick-slide.slick-current": {
        "& .timer::before": {
          animation: "next-slide 10s 1",
        },
      },
      "& .slick-arrow": {
        bottom: 24,
        top: "auto",
        right: 12,
        left: "auto",
        zIndex: 10,
        color: 'var(--' + overrides.controlColour + ')',
        transform: "none",
        width: 40,
        height: 40,
        [theme.breakpoints.up("md")]: {
          bottom: 40,
        },
        [theme.breakpoints.up(1142)]: {
          right: "calc(50% - 559px)",
        },
        "&.slick-prev": {
          right: 'auto',
          left: 12,
          [theme.breakpoints.up("md")]: {
            right: 112,
            left: 'auto',
          },
          [theme.breakpoints.up(1142)]: {
            right: "calc(50% - 459px)",
          },
        },
      },
      "& .slick-dots": {
        position: "absolute",
        bottom: 32,
        right: '50%',
        width: 32,
        height: 24,
        display: "block",
        color: 'var(--' + overrides.controlColour + ')',
        [theme.breakpoints.up("md")]: {
          bottom: 48,
          right: 80,
        },
        [theme.breakpoints.up(1142)]: {
          right: "calc(50% - 491px)",
        },
        "&::after": {
          content: '"/"',
          position: "absolute",
          fontSize: 14,
          lineHeight: "24px",
          right: 0,
          transform: "translateX(50%)",
          color: 'var(--' + overrides.controlColour + ')',
        },
        "& li": {
          position: "absolute",
          width: 32,
          height: 24,
          padding: 0,
          top: 0,
          left: 0,
          margin: 0,
          "& button": {
            display: "block",
            fontSize: 14,
            lineHeight: "24px",
            padding: 0,
            width: 32,
            fontFamily: theme.headerFont,
            height: 24,
            color: "transparent",
            textAlign: "center",
            "&::before": {
              display: "none",
            },
          },
          "&.slick-active": {
            "& button": {
              color: 'var(--' + overrides.controlColour + ')',
            },
          },
          "&:last-child": {
            "& button": {
              textShadow: '32px 0 var(--' + overrides.controlColour + ')',
            },
          },
        },
      },
    },
  },
})
