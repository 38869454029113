import { graphql } from "gatsby"

export const BblTestimonialBlockFragment = graphql`
  fragment BblTestimonialBlock on WpBlock {
    ... on WpBblTestimonialBlock {
      name
      attributes {
        className
        testimonialQuote
        testimonialName
        testimonialRole
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblTestimonialBlockPreview = `
  ... on BblTestimonialBlock {
      name
      attributes {
          className
          testimonialQuote
          testimonialName
          testimonialRole
      }
  }
`
