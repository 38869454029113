export default (theme, overrides) => ({
  // eslint-disable-line
  bannerNavSliderInner: {
    position: "relative",
    backgroundColor: "#333",
    "& .gatsby-image-wrapper": {
      zIndex: 1,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "50%",
      width: "100vw",
      transform: "translateX(-50%)",
    },
    "& .desktop-image": {
      display: "none !important",
      [theme.breakpoints.up("md")]: {
        display: "block !important",
      },
    },
    [theme.breakpoints.up("md")]: {
      "& .mobile-image": {
        display: "none !important",
      },
    },
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: "50%",
      background:
        "linear-gradient(90deg, rgba(0, 0, 0, 0.36) 0%, rgba(84, 84, 84, 0) 100%)",
      zIndex: 2,
    },
    "& .wrapper": {
      position: "relative",
      zIndex: 2,
      width: "100%",
      padding: "32px 0 64px",
      alignContent: "center",
      zIndex: 3,
      minHeight: 480,
      [theme.breakpoints.up("md")]: {
        alignContent: "center",
        minHeight: 800,
        padding: "64px 0 128px",
      },
      "& .con-wrap": {
        maxWidth: 520,
        "& .accent-heading": {
          marginBottom: 0,
        },
        "& .MuiTypography-root": {
          marginBottom: 32,
          color: "var(--" + overrides.headerColour + ")",
        },
        "& > p": {
          marginBottom: 24,
          color: "var(--" + overrides.paragraphColour + ")",
        },
        "& .button": {
          marginLeft: 12,
        },
      },
      "& .timer": {
        position: "absolute",
        bottom: 12,
        left: 12,
        right: 12,
        display: "block",
        height: 4,
        [theme.breakpoints.up("md")]: {
          bottom: 58,
          right: 172,
        },
        "&::before": {
          content: '""',
          display: "block",
          backgroundColor: "var(--" + overrides.controlColour + ")",
          height: 4,
          position: "absolute",
          left: 0,
          bottom: 0,
          top: 0,
          width: 0,
        },
        "&::after": {
          content: '""',
          display: "block",
          backgroundColor: "var(--" + overrides.controlColour + ")",
          height: 4,
          position: "absolute",
          left: 0,
          right: 0,
          opacity: 0.25,
          bottom: 0,
          top: 0,
          width: "100%",
        },
      },
    },
  },
})
