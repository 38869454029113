const styles = (theme, overrides) => ({
  logoRow: {
    padding: "30px 0",
    backgroundColor: "var(--" + overrides.backgroundColour + ")",
    "& .wrapper": {
      justifyContent: "center",
      "& .gatsby-image-wrapper": {
        margin: 12,
        flex: "0 0 auto",
        height: 120,
      },
    },
  },
})

export default styles
