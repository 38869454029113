import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { BblFeaturedIconsInnerBlockPreview } from "./queries";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';

export const BblFeaturedIconsInnerBlock = ({
    attributes,
    innerBlocks,
    parentAttributes,
    pageContext
}) => {

    const data = useStaticQuery(graphql`
        query featuredIconsInnerOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        featuredIcons {
                            paragraphColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.featuredIcons;
    const useStyles = makeStyles(theme => styles(theme, overrides));

    const classes = useStyles();

    const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext);

    return <div className={`featured-icons-inner ${attributes.className ? attributes.className : ''} ${classes.featuredIconsInner}`}>
        {blocks.map((block) => {
            return block;
        })}
    </div>
};

export { BblFeaturedIconsInnerBlockPreview }