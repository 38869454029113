import { graphql } from "gatsby"

export const BblImageLinkSliderBlockFragment = graphql`
  fragment BblImageLinkSliderBlock on WpBlock {
    ... on WpBblImageLinkSliderBlock {
      name
      attributes {
        __typename
        ... on WpBblImageLinkSliderBlockAttributes {
          className
          title
          mediaUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 1660
                aspectRatio: 1.75
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: COVER, cropFocus: CENTER }
                quality: 90
              )
            }
          }
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblImageLinkSliderBlockPreview = `
  ... on BblImageLinkSliderBlock {
      name
      attributes {
          __typename
          ... on BblImageLinkSliderBlockAttributes {
              className
              title
              mediaUrl
          }
      }
  }
`
