import { graphql } from "gatsby"

export const AcfLogoRowBlockFragment = graphql`
  fragment AcfLogoRowBlock on WpBlock {
    ... on WpAcfLogoRowBlock {
      name
      attributes {
        className
      }
      blockLogoRow {
        images {
          id
          uri
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                height: 120
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: COVER, cropFocus: CENTER }
                quality: 90
              )
            }
          }
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfLogoRowBlockPreview = `
    ... on AcfLogoRowBlock {
        name
        attributes {
            className
        }
        blockLogoRow {
            images {
                id
                uri
            }           
        }
    }
}`
