import { graphql } from "gatsby";

export const AcfLogoSliderBlockFragment = graphql`
fragment AcfLogoSliderBlock on WpBlock {
    ...on WpAcfLogoSliderBlock {
        name
        attributes {
            className
        }
        blockLogoSlider {
            logos {
                id
                uri
                localFile {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            width: 120,
                            placeholder: BLURRED,
                            formats: [AUTO, WEBP, AVIF],
                            transformOptions: {fit: COVER, cropFocus: CENTER},
                            quality: 90
                        )
                    }
                }
            }
        }
    }
}`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfLogoSliderBlockPreview = `
    ... on AcfLogoSliderBlock {
        name
        attributes {
            className
        }
        blockLogoSlider {
            logos {
                id
                uri
            }           
        }
    }
}`