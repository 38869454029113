import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { AcfLogoSliderBlockPreview } from "./queries";
import { makeStyles } from "@material-ui/styles";
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from "./styles";
import PreviewImage from "../../PreviewImage";

export const AcfLogoSliderBlock = ({
    block
}) => {

    const data = useStaticQuery(graphql`
        query logoSliderOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        logoSlider {
                            backgroundColour
                        }
                    }
                }
            }
        }
    `);

    let overrides = data.wp.themeOptions.ThemeOptions.logoSlider;

    const useStyles = makeStyles(theme => styles(theme, overrides));
    const classes = useStyles();
    
    let images = block.blockLogoSlider.logos;

    if(images === null) {
        return null;
    }

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 5000,
        pauseOnHover: false,
        cssEase: 'linear'
    }

    return <div className={"logo-slider " + classes.logoSlider}>
        <Slider {...settings}>
            {images.map((image, index) =>
                <PreviewImage key={index} alt={image.id} className="logo" fallbackUrl image={image.localFile} />
            )}
        </Slider>
    </div>
}

AcfLogoSliderBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
}

export { AcfLogoSliderBlockPreview }