import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { BblOverlapBannerBlockPreview } from "./queries";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import styles from './styles';

export const BblOverlapBannerBlock = ({
    attributes,
    innerBlocks,
    parentAttributes,
    pageContext
}) => {

    const data = useStaticQuery(graphql`
        query overlapBannerOverrides {
            wp {
                themeOptions {
                    ThemeOptions {
                        overlapBanner {
                            backgroundColour
                            headerColour
                        }
                    }
                }
            }
        }`);

    let overrides = data.wp.themeOptions.ThemeOptions.overlapBanner;

    const useStyles = makeStyles(theme => styles(theme, overrides));
    const classes = useStyles();
    
    const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext);

    return <div className={` ${attributes.className ? attributes.className : ''} ${classes.overlapBanner} `} >
        <div className="wrapper">
            <div className="con-wrap">
                {blocks[1]}
                {blocks[2]}
            </div>
            {blocks[0]}
        </div>
    </div>
};

export { BblOverlapBannerBlockPreview }