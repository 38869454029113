import { graphql } from "gatsby";

export const BblFeaturedIconsBlockFragment = graphql`
fragment BblFeaturedIconsBlock on WpBlock {
  ... on WpBblFeaturedIconsBlock {
      name
      attributes {
          className
          featuredIconsTitle
      }
    }
}`;

// This is the string version of this that is used within previews and without WP prefixes
export const BblFeaturedIconsBlockPreview = `
  ... on BblFeaturedIconsBlock {
      name
      attributes {
          className
          featuredIconsTitle
      }
  }
`;