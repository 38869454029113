import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks, mergeBlocks } from "../../../services/builder"
import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const BblIconRowInnerBlock = props => {
  // console.log(props);

  const data = useStaticQuery(graphql`
    query iconRowInnerOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            iconRow {
              headerColour
              paragraphColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.iconRow

  if (
    props.innerBlocks[1].attributes.textColor === "" &&
    overrides.headerColour !== "on-secondary"
  ) {
    props.innerBlocks[1].attributes.textColor = overrides.headerColour
  }

  if (
    props.innerBlocks[2].attributes.textColor === "" &&
    overrides.paragraphColour !== "on-secondary"
  ) {
    props.innerBlocks[2].attributes.textColor = overrides.paragraphColour
  }

  const mergedBlocks = mergeBlocks(props.innerBlocks, props.block.innerImage)
  const blocks = buildBlocks(
    mergedBlocks,
    props.parentAttributes,
    props.pageContext
  )
  const classes = useStyles()

  return (
    <div
      className={` ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.iconRowInner} `}
    >
      {blocks.map(block => {
        return block
      })}
    </div>
  )
}

export const BblIconRowInnerBlockFragment = graphql`
  fragment BblIconRowInnerBlock on WpBlock {
    ... on WpBblIconRowInnerBlock {
      name
      attributes {
        className
      }
      innerImage: innerBlocks {
        ... on WpCoreImageBlock {
          name
          originalContent
          attributes {
            __typename
            ... on WpCoreImageBlockAttributes {
              url
              alt
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 56
                    aspectRatio: 1
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                    quality: 90
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblIconRowInnerBlockPreview = `
    ... on BblIconRowInnerBlock {
        name
        attributes {
            className
        }
        inner: innerBlocks {
            ... on WpCoreImageBlock {
                name
                originalContent {
                    attributes {
                        __typename
                        ... on WpCoreImageBlockAttributes {
                            url
                        }
                    }
                }
            }
        }
    }
`
