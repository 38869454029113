import { graphql } from "gatsby"

export const BblBannerNavSliderBlockFragment = graphql`
  fragment BblBannerNavSliderBlock on WpBlock {
    ... on WpBblBannerNavSliderBlock {
      name
      attributes {
        __typename
        ... on WpBblBannerNavSliderBlockAttributes {
          className
        }
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const BblBannerNavSliderBlockPreview = `
... on BblBannerNavSliderBlock {
    name
    attributes {
        __typename
        ... on BblBannerNavSliderBlockAttributes {
            className
        }
    }
}
`
