import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { BblImageLinkSliderBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Slider from "react-slick/lib"
import styles from "./styles"

export const BblImageLinkSliderBlock = ({
  innerBlocks,
  parentAttributes,
  pageContext,
  attributes,
}) => {
  const data = useStaticQuery(graphql`
    query imageLinkSliderOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            imageLinkSlider {
              backgroundColour
              sliderTitleColour
              sliderControlsColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.imageLinkSlider
  const useStyles = makeStyles(theme => styles(theme, overrides))

  const classes = useStyles()

  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)

  let image = null

  if (attributes.mediaUrlSharp) {
    if (attributes.mediaUrlSharp.childImageSharp) {
      image = attributes.mediaUrlSharp.publicURL
    }
  } else {
    image = attributes.mediaUrl
  }

  const settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
  }

  return (
    <div
      className={`image-link-slider ${
        attributes.className ? attributes.className : ""
      } ${classes.imageLinkSlider}`}
    >
      <h2>{attributes.title}</h2>
      <Slider {...settings}>
        {blocks.map(block => {
          return block
        })}
      </Slider>
      <div
        className="bg-image"
        style={{ backgroundImage: "url(" + image + ")" }}
      />
    </div>
  )
}

BblImageLinkSliderBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { BblImageLinkSliderBlockPreview }
