const styles = (theme, overrides) => ({
  imageLinkSlider: {
    position: "relative",
    padding: "90px 0 190px;",
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      left: "50%",
      top: 0,
      bottom: 255,
      width: "100vw",
      transform: "translateX(-50vw)",
      backgroundColor: "var(--" + overrides.backgroundColour + ")",
      [theme.breakpoints.up("md")]: {
        bottom: 380,
      },
    },
    "& .slick-slider": {
      position: "relative",
      zIndex: "1",
      width: "calc(100% + 34px)",
      maxWidth: "1142px",
      margin: "0 auto -17px",
      height: "336px !important",
      "& .slick-slide": {
        padding: "12px 12px 24px",
      },
      "& .slick-dots": {
        top: "-35px",
        bottom: "auto",
        "& li button:before": {
          color: "var(--" + overrides.sliderControlsColour + ")",
          fontSize: 16,
        },
      },
    },
    "& h2": {
      display: "block",
      marginTop: 0,
      maxWidth: 1142,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      fontFamily: theme.headerFont,
      padding: "0 12px",
      fontWeight: "bold",
      textAlign: "center",
      fontSize: 32,
      lineHeight: 1.25,
      color: "var(--" + overrides.sliderTitleColour + ")",
      position: "relative",
      zIndex: "1",
      marginBottom: 60,
      [theme.breakpoints.up("lg")]: {
        fontSize: 48,
        lineHeight: 1.08,
      },
    },
    "& .bg-image": {
      display: "block",
      position: "absolute",
      left: "50%",
      bottom: 0,
      height: 255,
      width: "100vw",
      transform: "translateX(-50vw)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      [theme.breakpoints.up("md")]: {
        height: 380,
      },
    },
  },
})

export default styles
