import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { buildBlocks } from "../../../services/builder"
// import { isBrowser } from "../../../helpers"
import { makeStyles } from "@material-ui/styles"

import styles from "./styles"

const useStyles = makeStyles(theme => styles(theme))

export const AcfFacebookPagePluginBlock = props => {
  const data = useStaticQuery(graphql`
    query facebookPagePluginBlock {
      wp {
        themeOptions {
          ThemeOptions {
            facebookUrl
            facebookPlugin {
              backgroundColour
              headerColour
            }
          }
        }
      }
    }
  `)

  const classes = useStyles()

  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])

  const overrides = data.wp.themeOptions.ThemeOptions.facebookPlugin

  if (
    props.innerBlocks[0].attributes.textColor === "" &&
    overrides.headerColour !== "black"
  ) {
    props.innerBlocks[0].attributes.textColor = overrides.headerColour
  }
  const blocks = buildBlocks(
    props.innerBlocks,
    props.attributes,
    props.pageContext
  )

  const {
    facebookPageUrl,
    // hideCoverPhoto,
    // tabs,
    // useSmallHeader,
    // showFriendsFaces,
  } = props.block.blockFacebookPagePlugin

  let finalUrl = facebookPageUrl

  if (facebookPageUrl === "" || facebookPageUrl === null) {
    finalUrl = data.wp.themeOptions.ThemeOptions.facebookUrl
  }

  return (
    <div
      className={`${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.facebookPagePlugin} `}
      data-override-background={
        overrides.backgroundColour !== "tertiary"
          ? overrides.backgroundColour
          : null
      }
    >
      <div className="wrapper">
        <div className="blocks">
          {blocks.map(block => {
            return block
          })}
        </div>

        {loaded && (
          <div className="facebook-frame">
            <div
              className="fb-page"
              data-href={finalUrl}
              data-tabs="timeline"
              data-width="500"
              data-height="500"
              data-small-header="true"
              data-adapt-container-width="false"
              data-hide-cover="true"
              data-show-facepile="false"
            >
              <blockquote cite={finalUrl} class="fb-xfbml-parse-ignore">
                <a href={finalUrl}>{finalUrl}</a>
              </blockquote>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

AcfFacebookPagePluginBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export const AcfFacebookPagePluginBlockFragment = graphql`
  fragment AcfFacebookPagePluginBlock on WpBlock {
    ... on WpAcfFacebookPagePluginBlock {
      name
      attributes {
        className
      }
      blockFacebookPagePlugin {
        facebookPageUrl
        fieldGroupName
        hideCoverPhoto
        showFriendsFaces
        tabs
        useSmallHeader
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfFacebookPagePluginBlockPreview = `
  ... on WpAcfFacebookPagePluginBlock {
      name
      attributes {
          className
      }
      blockFacebookPagePlugin {
        facebookPageUrl
        fieldGroupName
        hideCoverPhoto
        showFriendsFaces
        tabs
        useSmallHeader
      }
  }

`
