export default theme => ({
  // eslint-disable-line
  cardBlock: {
    borderRadius: theme.border.radius,
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadow,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",
    gridColumnGap: 0,
    gridRowGap: 0,
    "& .image": {
      aspectRatio: 1.78,
      gridArea: "1/1/2/2",
    },
    "& .con-wrap": {
      gridArea: "2/1/3/2",
      padding: 32,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      "& .min-height": {
        minHeight: 150,
        paddingBottom: 1,
      },
      "& h1, & h2, & h3, & h4, & h5, & h6, & p": {
        color: theme.palette.secondary.contrastText,
        padding: 0,
      },
    },
    "& .links": {
      "& > a": {
        margin: 0,
      },
    },
  },
})
