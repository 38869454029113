const styles = (theme, overrides) => ({
  testimonialSlider: {
    padding: "50px 0",
    backgroundColor: "var(--" + overrides.backgroundColour + ")",
    [theme.breakpoints.up("md")]: {
      padding: "100px 0",
    },
    "& h2": {
      flex: "0 0 100%",
      maxWidth: "100%",
      display: "block",
      position: "relative",
      marginTop: 0,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      fontFamily: theme.headerFont,
      padding: "0 12px",
      fontWeight: "bold",
      fontSize: 32,
      lineHeight: 1.25,
      marginBottom: 30,
      [theme.breakpoints.up("lg")]: {
        fontSize: 48,
        marginBottom: 50,
        lineHeight: 1.08,
      },
    },
    "& .testimonial": {
      padding: "0 !important",
      backgroundColor: "transparent !important",
      "& p": {
        flex: "0 0 100%",
        maxWidth: "100%",
        marginLeft: 0,
        marginRight: 0,
        "&.testimonial-name, &.testimonial-role": {
          flex: "0 0 50%",
          maxWidth: "50%",
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    "& .slick-slider": {
      flex: "0 0 100%",
      maxWidth: "100%",
      padding: "30px 0 30px 90px",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 83.333%",
        maxWidth: "83.333%",
        marginLeft: "8.333%",
        padding: "60px 0 60px 120px",
      },
      "&::before": {
        position: "absolute",
        border: "3px solid",
        borderRight: "none",
        borderColor: "var(--" + overrides.accentLineColour + ")",
        content: '""',
        display: "block",
        width: 40,
        top: 0,
        left: 60,
        bottom: 0,
        [theme.breakpoints.up("md")]: {
          left: 90,
        },
      },
    },
    "& .slick-dots": {
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
      width: 60,
      bottom: "auto",
      [theme.breakpoints.up("md")]: {
        left: 15,
      },
      "& li": {
        width: 20,
        height: 20,
        margin: 10,
        "& button": {
          width: 20,
          height: 20,
          backgroundColor: "var(--" + overrides.controlColour + ")",
          borderRadius: "50%",
          opacity: 0.5,
          transition: "opacity .5s ease-in-out",
          "&::before": {
            content: '""',
          },
        },
        "&.slick-active": {
          "& button": {
            opacity: 1,
          },
        },
      },
    },
  },
})

export default styles
