import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, navigate } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Pagination from '@material-ui/lab/Pagination';
import themeOptions from "../../../../.brew-cache/theme.json";
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';
import PreviewImage from '../../PreviewImage';

import { CoreHeadingBlock } from "../CoreHeadingBlock";
import { CoreParagraphBlock } from "../CoreParagraphBlock";
import { AcfButtonBlock } from "../AcfButtonBlock";

import TodayIcon from '@material-ui/icons/Today';

const useStyles = makeStyles(theme => styles(theme));

export const BblPostListingFullWidthBlock = (props) => {

    const classes = useStyles();
    /**
     * The blog posts should be passed through from the parent page directly to this component
     * This will only happen on custom page templates like the blog list page
     * Preview will not have the posts information for this page due to how the query works, so if this not set
     * use some placeholder data to populate the posts
     * */
    let { posts, pageNumber, totalPages } = props.pageContext;

    // Toggle output based on Attributes
    // props.attributes.display : list / grid

    if (typeof (posts) === 'undefined') {
        // set some sample data
        posts = [
            {
                "node": {
                    title: "Example Post",
                    date: "2020-01-01 00:00:00",
                    excerpt: "<p>This is just a test post used for previewing the listing page. Real posts will appear here when this page is published.</p>",
                    uri: '/',
                    featuredImage: {
                        node: {
                            mediaItemUrl: ""
                        }
                    }
                }
            }
        ]
    }

    let paginationSlug = `/${themeOptions.blogPath}`;

    if (props.pageContext.catSlug) {
        paginationSlug = paginationSlug + '/' + props.pageContext.catSlug;
    }

    const data = useStaticQuery(graphql`
    query PostListingFullWidthBlock {
      wp {
          themeOptions {
              ThemeOptions {
                  postListingFullWidth {
                      backgroundColour
                      postBackgroundColour
                      postHeaderColour
                      postDateColour
                      postExcerptColour
                      postCategoryColour
                      postBorderColour
                  }
              }
          }
      }
    }
    `);

    const overrides = data.wp.themeOptions.ThemeOptions.postListingFullWidth;

    let btnStyle = 'secondary'

    if (props.attributes.className === 'primary') {
        btnStyle = 'primary'
    }


    return (
        <div className={`${props.attributes.className ? props.attributes.className : ''} ${classes.postListingFullWidth}`}
            data-override-background={overrides.backgroundColour !== 'background' ? overrides.backgroundColour : null}

        >
            <div className="wrapper">
                {posts.map((post, index) => {
                    return <div className="list-item" key={post.node.id}
                        data-override-background={overrides.postBackgroundColour !== 'tertiary' ? overrides.postBackgroundColour : null}
                        data-override-border={overrides.postBorderColour !== 'black' ? overrides.postBorderColour : null}


                    >
                        <PreviewImage
                            image={post.node.featuredImage.node.localFile}
                            fallbackUrl={post.node.featuredImage.node.mediaItemUrl}
                            alt={post.node.featuredImage.node.altText}
                            fixedPadding="72%"
                        />
                        <div className="con-wrap">
                            <div className="meta">
                                <p className="date"
                                    data-override-color={overrides.postDateColour !== 'accent' ? overrides.postDateColour : 'accent'}

                                ><TodayIcon /> {post.node.date}</p>
                                <p className="category"
                                    data-override-color={overrides.postCategoryColour !== 'accent' ? overrides.postCategoryColour : 'accent'}>{post.node.categories?.nodes[0].name}</p>
                            </div>
                            <CoreHeadingBlock
                                attributes={{
                                    textAlign: "left",
                                    anchor: "",
                                    className: "",
                                    content: ReactHtmlParser(post.node.title),
                                    level: 3,
                                    textColor: overrides.postHeaderColour !== 'black' ? overrides.postHeaderColour : 'black',
                                    backgroundColor: "",
                                    __typename: "WpCoreHeadingBlockAttributes",
                                }}
                                innerBlocks={[]}
                            />
                            <CoreParagraphBlock
                                attributes={{
                                    align: "left",
                                    className: "",
                                    content: ReactHtmlParser(post.node.excerpt.replace('<p>', '').replace('</p>', '')),
                                    textColor: overrides.postExcerptColour !== 'on-tertiary' ? overrides.postExcerptColour : 'on-tertiary',
                                    backgroundColor: "",
                                    fontSize: "",
                                    __typename: "WpCoreParagraphBlockAttributes",
                                }}
                                innerBlocks={[]}
                            />
                            <AcfButtonBlock
                                attributes={{
                                    className: "",
                                }}
                                block={{
                                    ButtonFields: {
                                        buttonLink: {
                                            target: "",
                                            title: props.attributes.btnText,
                                            url: post.node.uri,
                                        },
                                        buttonStyle: btnStyle,
                                        icon: "",
                                        showIcon: "none",
                                    }
                                }}
                                innerBlocks={[]}
                            />
                        </div>
                    </div>
                })}
                {totalPages > 1 &&
                    <Pagination count={totalPages} page={pageNumber} onChange={(e, value) => { value === 1 ? navigate(paginationSlug) : navigate(`${paginationSlug}/page/${value}`) }} />
                }
            </div>
        </div>
    )
};

BblPostListingFullWidthBlock.propTypes = {
    attributes: PropTypes.object.isRequired
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const BblPostListingFullWidthBlockFragment = graphql`
  fragment BblPostListingFullWidthBlock on WpBlock {
    ... on WpBblPostListingFullWidthBlock {
      name
      attributes {
        __typename
        ... on WpBblPostListingFullWidthBlockAttributes {
            className
            btnText
        }
      }
    }
  }`;

export const BblPostListingFullWidthBlockPreview = `
... on BblPostListingFullWidthBlock {
  name
  attributes {
    __typename
    ... on BblPostListingFullWidthBlockAttributes {
      className
      btnText
    }
  }
}
`;