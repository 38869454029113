const styles = (theme, overrides) => ({
  formIntro: {
    backgroundColor: "var(--" + overrides.backgroundColour + ")",
    padding: "50px 0",
    [theme.breakpoints.up("md")]: {
      padding: "100px 0",
    },
    "& .wrapper": {
      flexDirection: "column",
      "& .MuiTypography-root": {
        marginBottom: 24,
        color: "var(--" + overrides.headerColour + ")",
        [theme.breakpoints.up("md")]: {
          width: "66.666%",
          margin: "0 auto 24px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "50%",
        },
      },
      "& > p": {
        marginBottom: 40,
        color: "var(--" + overrides.paragraphColour + ")",
        [theme.breakpoints.up("md")]: {
          width: "66.666%",
          margin: "0 auto 40px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "50%",
        },
      },
      "& .form-wrap": {
        padding: 1,
        backgroundColor: "var(--" + overrides.formBackgroundColour + ")",
        [theme.breakpoints.up("md")]: {
          width: "66.666%",
          margin: "0 auto 40px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "50%",
        },
        "& > div": {
          margin: 24,
          width: "calc(100% - 48px)",
          [theme.breakpoints.up("md")]: {
            margin: "6.25% 12.5%",
            width: "75%",
          },
          [theme.breakpoints.up("lg")]: {
            margin: "8.333% 16.666%",
            width: "66.666%",
          },
          "& .gfield": {
            "& .ginput_container": {
              "& > label": {
                color:
                  "var(--" + overrides.formSubmissionMessageTextColour + ")",
              },
              '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
                color:
                  "var(--" + overrides.formSubmissionMessageTextColour + ")",
                backgroundColor: "transparent",
                // "-webkit-text-fill-color":
                //   theme.palette.primary.contrastText + " !important",
                // "-webkit-box-shadow": "0 0 0px 100px " +    theme.palette.primary.main +     " inset !important",
              },
              "& select": {
                backgroundImage:
                  "linear-gradient(45deg, transparent 50%, " +
                  "var(--" +
                  overrides.formSubmissionMessageTextColour +
                  ")" +
                  " 50%), linear-gradient(135deg, " +
                  "var(--" +
                  overrides.formSubmissionMessageTextColour +
                  ")" +
                  " 50%, transparent 50%)",
              },
              "& .after": {
                background:
                  "linear-gradient(to right, " +
                  "var(--" +
                  overrides.formSubmissionMessageTextColour +
                  ")" +
                  " 50%, " +
                  "var(--" +
                  overrides.formSubmissionMessageTextColour +
                  ")" +
                  " 50%)",
              },
            },
            "&.gravityform__field__checkbox, &.gravityform__field__radio": {
              "& label": {
                color:
                  "var(--" + overrides.formSubmissionMessageTextColour + ")",
              },
              "& .gfield_checkbox, & .gfield_radio": {
                '& [type="checkbox"], & [type="radio"]': {
                  "& + label": {
                    "&::before": {
                      border:
                        "2px solid " +
                        "var(--" +
                        overrides.formSubmissionMessageTextColour +
                        ")",
                      background: "transparent",
                    },
                  },
                },
              },
            },
            "& .gravityforms__description": {
              color: "var(--" + overrides.formSubmissionMessageTextColour + ")",
            },
          },
          "& .gform_confirmation_message": {
            color: "var(--" + overrides.formSubmissionMessageTextColour + ")",
          },
          '&[data-form-style="outline"]': {
            "& .gfield": {
              "& .ginput_container": {
                '& input:not([type="checkbox"], [type="radio"]), & select, & textarea': {
                  border:
                    "1px solid " +
                    "var(--" +
                    overrides.formSubmissionMessageTextColour +
                    ")",
                },
              },
            },
          },
        },
      },
    },
  },
})
export default styles
