import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { BblTestimonialSliderBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styles from "./styles"

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  fade: true,
  adaptiveHeight: false,
}

export const BblTestimonialSliderBlock = ({
  innerBlocks,
  attributes,
  parentAttributes,
  pageContext,
}) => {
  const data = useStaticQuery(graphql`
    query testimonialSliderOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            testimonial {
              backgroundColour
            }
            testimonialSlider {
              sliderTitleColour
              controlColour
              accentLineColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.testimonialSlider
  overrides.backgroundColor =
    data.wp.themeOptions.ThemeOptions.testimonial.backgroundColor
  const useStyles = makeStyles(theme => styles(theme, overrides))

  const classes = useStyles()

  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)

  return (
    <div
      className={`testimonial-slider ${
        attributes.className ? attributes.className : ""
      } ${classes.testimonialSlider}`}
    >
      <div className="wrapper">
        <h2>{attributes.testimonialSliderHeading}</h2>
        <Slider {...settings}>
          {blocks.map(block => {
            return <div className="slide">{block}</div>
          })}
        </Slider>
      </div>
    </div>
  )
}

export { BblTestimonialSliderBlockPreview }
