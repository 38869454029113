import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { BblBannerNavSliderInnerBlockPreview } from "./queries"
import { makeStyles } from "@material-ui/styles"
import { buildBlocks } from "../../../services/builder"
import styles from "./styles"
import PreviewImage from "../../PreviewImage"

export const BblBannerNavSliderInnerBlock = ({
  attributes,
  innerBlocks,
  pageContext,
  parentAttributes,
  block,
}) => {
  const data = useStaticQuery(graphql`
    query bannerNavSliderInnerOverrides {
      wp {
        themeOptions {
          ThemeOptions {
            bannerNavSlider {
              controlColour
              headerColour
              paragraphColour
            }
          }
        }
      }
    }
  `)

  let overrides = data.wp.themeOptions.ThemeOptions.bannerNavSlider

  const useStyles = makeStyles(theme => styles(theme, overrides))

  const blocks = buildBlocks(innerBlocks, parentAttributes, pageContext)
  const classes = useStyles()

  return (
    <div
      className={`${attributes.className ? attributes.className : ""} ${
        classes.bannerNavSliderInner
      }`}
    >
      <PreviewImage
        image={attributes.localFile}
        fallbackUrl={attributes.mediaUrl}
        alt=""
        fixedPadding="60%"
        className="desktop-image"
      />
      <PreviewImage
        image={attributes.localFileMobile}
        fallbackUrl={attributes.mediaUrlMobile}
        alt=""
        fixedPadding="125%"
        className="mobile-image"
      />
      <div className="wrapper">
        <div className="con-wrap">
          {blocks.map(block => {
            return block
          })}
        </div>
        <div className="timer" />
      </div>
    </div>
  )
}

BblBannerNavSliderInnerBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
}

export { BblBannerNavSliderInnerBlockPreview }
